import Calendar from "../calendar";
import { isMoblie, NPSTOCKS_LIVE_URL, NPSTOCKS_URL } from "../Utils";

export default class TodayEvent {
  constructor({ token, id }) {
    this.config = { token, id };
    this.mainContainer = document.querySelector(id);
    this.mainContainer.innerHTML = "";
    this.style = document.createElement("style");
    this.style.innerHTML = `@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
            ${id}{font-family: 'Inter', sans-serif;}`;
    this.mainContainer.appendChild(this.style);
    this.data = {};
    this.date = new Date();
    this.day = this.date.getDate();
    this.month = this.date.getMonth();
    this.year = this.date.getFullYear();
    this.isResponseEmpty = false;
    this.fetchErrror = false;
  }

  async fetchData() {
    try {
      await isMoblie({ encyryptedText: this.config.token });
      const liveData = await fetch(
        `${NPSTOCKS_LIVE_URL}/widget/todays-events/${this.year}-${
          this.month + 1
        }-${this.day}`
      );
      const tempdata = await liveData.json();
      this.data = tempdata.response;
      this.data.length === 0
        ? (this.isResponseEmpty = true)
        : (this.isResponseEmpty = false);
      this.fetchErrror = false;
    } catch (e) {
      console.log(e);
      this.fetchErrror = true;
    }
  }

  async init() {
    await this.fetchData();
    this.renderContent();
  }

  calendarDiv() {
    const mainContainer = document.createElement("div");
    const container = document.createElement("div");
    container.id = "calendar-widget";
    mainContainer.id = "calendar-widget-container";
    mainContainer.classList.add(
      "absolute",
      "z-10",
      "bg-black/30",
      "h-[100%]",
      "w-[100%]",
      "flex",
      "flex-col",
      "justify-center",
      "items-center",
      "hidden"
    );

    mainContainer.append(container);
    return mainContainer;
  }

  onclick = () => {
    this.day = this.calendar.date.getDate();
    this.month = this.calendar.date.getMonth();
    this.year = this.calendar.date.getFullYear();
    this.mainContainer.innerHTML = "";
    this.init();
  };

  renderContent() {
    this.mainContainer.classList.add(
      "m-2",
      "box-border",
      "select-none",
      "flex",
      // "min-w-[375px]",
      // "min-w-[375px]",
      // "min-h-[596px]",
      // "h-[90vh]",
      "h-[739px]",
      "flex-col",
      "overflow-hidden",
      "rounded-md",
      // "border-2",
      "bg-white",
      "dark:bg-dark",
      "dark:text-darkText",
      "relative"
    );

    this.mainContainer.append(this.calendarDiv());
    this.calendar = new Calendar({
      token: "None",
      id: "#calendar-widget",
      day: this.day,
      month: this.month,
      year: this.year,
      onclick: this.onclick,
    });
    this.calendar.init();

    const bodyContainer = document.createElement("div");
    bodyContainer.classList.add("overflow-y-auto", "xsm:scrollbar");
    this.mainContainer.append(this.header());

    if (this.fetchErrror) {
      bodyContainer.classList.add(
        "flex",
        "items-center",
        "justify-center",
        "h-screen"
      );
      bodyContainer.innerHTML = `<div class="text-black/50 dark:text-white/50">Failed to fetch data.</div>`;
    } else {
      if (!this.isResponseEmpty) {
        for (let data of this.data) {
          bodyContainer.append(
            this.activity(
              data.Company + data.Details,
              data.Type,
              data.Company,
              data.Details
            ),
            this.detailsTab(
              data.Company + data.Details,
              data.Agenda,
              data.InputDate,
              data.EventDate,
              data.OpenDate,
              data.CloseDate,
              data.BookCloseDate,
              data.AllotmentDate,
              data.Time
            )
          );
        }
      } else {
        bodyContainer.classList.add(
          "flex",
          "items-center",
          "justify-center",
          "h-screen"
        );
        bodyContainer.innerHTML = `<div class="text-black/50 dark:text-white/50">No Events found for this day.</div>`;
      }
    }

    this.mainContainer.append(bodyContainer);
  }

  header() {
    const headerDiv = document.createElement("div");
    headerDiv.classList.add(
      "flex",
      "items-center",
      "justify-between",
      "border-b",
      "border-lightBorderColor",
      "dark:border-darkBorderColor",
      "pl-[18px]",
      "md:pr-[25px]",
      "pr-[18px]",
      "py-[9px]"
    );
    const dateDiv = document.createElement("div");
    dateDiv.classList.add(
      "pt-[12px]",
      "text-[12px]",
      "font-[400]",
      "leading-[15px]"
    );
    dateDiv.innerText = `${this.day}/${this.month + 1}/${this.year}`;

    const calenderLogo = document.createElement("div");
    calenderLogo.onclick = () => {
      const calendarWidget = document.getElementById(
        "calendar-widget-container"
      );
      if (calendarWidget.classList.contains("hidden")) {
        calendarWidget.classList.remove("hidden");
      }
    };
    // calenderLogo.className = "cursor-pointer";
    calenderLogo.className = "";
    calenderLogo.innerHTML = `<svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.7103 4.19565H5.28906C4.685 4.19565 4.19531 4.68534 4.19531 5.2894V22.7106C4.19531 23.3147 4.685 23.8044 5.28906 23.8044H22.7103C23.3144 23.8044 23.8041 23.3147 23.8041 22.7106V5.2894C23.8041 4.68534 23.3144 4.19565 22.7103 4.19565Z"
      stroke="#2962FF"
      stroke-width="1.3125"
    />
    <path
      d="M4.19531 9.00815H23.8041"
      stroke="#2962FF"
      stroke-width="1.3125"
    />
    <path
      d="M8.61865 4.19565V2.00815"
      stroke="#2962FF"
      stroke-width="1.3125"
    />
    <path
      d="M19.1016 4.19565V2.00815"
      stroke="#2962FF"
      stroke-width="1.3125"
    />
    <path
      d="M9.75215 12.215H7.06152V14.9056H9.75215V12.215Z"
      stroke="#2962FF"
      stroke-width="1.3125"
    />
    <path
      d="M15.2819 12.215H12.5913V14.9056H15.2819V12.215Z"
      stroke="#2962FF"
      stroke-width="1.3125"
    />
    <path
      d="M20.7419 12.215H18.0513V14.9056H20.7419V12.215Z"
      stroke="#2962FF"
      stroke-width="1.3125"
    />
    <path
      d="M9.84785 17.36H7.15723V20.0506H9.84785V17.36Z"
      stroke="#2962FF"
      stroke-width="1.3125"
    />
    <path
      d="M15.3825 17.36H12.6919V20.0506H15.3825V17.36Z"
      stroke="#2962FF"
      stroke-width="1.3125"
    />
    <path
      d="M20.8425 17.36H18.1519V20.0506H20.8425V17.36Z"
      stroke="#2962FF"
      stroke-width="1.3125"
    />
  </svg>`;

    headerDiv.append(dateDiv, calenderLogo);
    return headerDiv;
  }

  activity(id, type, announcementOf, details) {
    const divContainer = document.createElement("div");
    divContainer.classList.add(
      "border-b",
      "border-lightBorderColor",
      "dark:border-darkBorderColor",
      "pl-[18px]",
      "pr-[25px]",
      "hover:bg-lightHoverColor",
      "py-[5px]",
      // "cursor-pointer",
      "dark:hover:bg-slate-800"
    );
    divContainer.onclick = () => {
      const hiddenElement = document.getElementById(id);
      if (hiddenElement.className.includes("hidden")) {
        divContainer.classList.add("bg-lightHoverColor", "dark:bg-slate-800");
        hiddenElement.className =
          "border-b border-lightBorderColor dark:border-darkBorderColor pl-[18px] pr-[25px] pt-[16px] pb-[42px]";
      } else {
        hiddenElement.className =
          "border-b border-lightBorderColor dark:border-darkBorderColor pl-[18px] pr-[25px] pt-[16px] pb-[42px] hidden";
        divContainer.classList.remove("bg-lightHoverColor");
        divContainer.classList.remove("dark:bg-slate-800");
      }
    };
    const activityName = document.createElement("div");
    activityName.classList.add(
      "pt-[3px]",
      "text-[9px]",
      "font-[400]",
      "leading-[11px]",
      "text-titleLabelDarkText"
    );
    activityName.innerText = type.toUpperCase();
    const gridDiv = document.createElement("div");
    gridDiv.classList.add("xsm:grid", "xsm:grid-cols-[1.5fr,1fr;]");
    const eventName = document.createElement("div");
    eventName.classList.add(
      "text-[12px]",
      "font-[400]",
      "leading-[15px]",
      "text-clickAbleLinkColor",
      "dark:text-cyan-500"
    );
    eventName.innerText = announcementOf;
    const detailContainer = document.createElement("div");
    detailContainer.classList.add(
      "text-[12px]",
      "font-[400]",
      "leading-[15px]",
      "pt-[6px]",
      "md:pt-[0px]"
    );
    detailContainer.innerText = details;

    gridDiv.append(eventName, detailContainer);

    divContainer.append(activityName, gridDiv);
    return divContainer;
  }

  detailsTab(
    idText,
    details,
    inputDate,
    eventDate,
    openDate,
    closeDate,
    bookClose,
    allotment,
    time
  ) {
    const detailDiv = document.createElement("div");
    detailDiv.classList.add(
      "border-b",
      "border-lightBorderColor",
      "dark:border-darkBorderColor",
      "pl-[18px]",
      "pr-[25px]",
      "pt-[16px]",
      "pb-[42px]",
      "hidden"
    );
    detailDiv.id = idText;

    const detail = document.createElement("div");
    detail.classList.add(
      "text-[10px]",
      "font-[400]",
      "leading-[12px]",
      "text-titleLabelDarkText"
    );
    detail.innerText = "DETAILS";

    const detailsContainer = document.createElement("div");

    for (let data of details.split("##")) {
      const information = document.createElement("div");
      information.classList.add(
        "mt-[6px]",
        "text-[12px]",
        "font-[500]",
        "leading-[15px]"
      );
      information.innerText = data;
      detailsContainer.append(information);
    }

    // const information = document.createElement("div");
    // information.classList.add(
    //   "mt-[6px]",
    //   "text-[12px]",
    //   "font-[500]",
    //   "leading-[15px]"
    // );

    // information.innerText = details ?? "-";

    const grid = document.createElement("div");
    grid.classList.add(
      "grid",
      "xsm:grid-cols-4",
      "mt-[25px]",
      "grid-cols-3",
      "grid-rows-2",
      "xsm:gap-y-[17px]",
      "gap-y-[24px]"
    );
    grid.append(
      // this.innerGridDiv("INPUT DATE", inputDate ?? "-"),
      this.innerGridDiv("Announcement".toUpperCase(), inputDate ?? "-"),
      this.innerGridDiv("EVENT DATE", eventDate ?? "-"),
      this.innerGridDiv("OPEN DATE", openDate ?? "-"),
      this.innerGridDiv("CLOSE DATE", closeDate ?? "-"),
      this.innerGridDiv("BOOKCLOSE", bookClose ?? "-"),
      this.innerGridDiv("ALLOTMENT", allotment ?? "-"),
      this.innerGridDiv("TIME", time ?? "-", "xsm:block hidden")
    );
    // detailDiv.append(detail, information, grid);
    detailDiv.append(detail, detailsContainer, grid);
    return detailDiv;
  }

  innerGridDiv(titleText, valueText, styles = "block") {
    const containerDiv = document.createElement("div");
    containerDiv.className = styles;
    const title = document.createElement("div");
    title.classList.add(
      "text-[10px]",
      "font-[400]",
      "leading-[12px]",
      "text-titleLabelDarkText"
    );
    title.innerText = titleText;

    const value = document.createElement("div");
    value.classList.add(
      "mt-[5px]",
      "text-[12px]",
      "font-[400]",
      "leading-[15px]"
    );
    value.innerText = valueText;

    containerDiv.append(title, value);
    return containerDiv;
  }
}
