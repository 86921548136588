import { validateToken } from "../Auth";
import { commaSeperatedFloats, isMoblie, NPSTOCKS_LIVE_URL } from "../Utils";

export default class Announcement {
  constructor({ token, id }) {
    this.config = { token, id };
    this.mainContainer = document.querySelector(id);
    this.mainContainer.innerHTML = "";
    this.style = document.createElement("style");
    this.style.innerHTML = `@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
                    ${id}{font-family: 'Inter', sans-serif;}
                    #Announcement-tabs::-webkit-scrollbar {
                      display: none;
                  }`;
    this.mainContainer.appendChild(this.style);
    this.data = {};
    this.fetchError = false;
    this.activeButtonStyle =
      // "cursor-pointer select-none rounded-[5px] bg-btnActiveColor px-[20px] py-[4px] text-[12px] font-[400] leading-4 text-white";
      // "cursor-pointer select-none rounded-[5px] bg-btnActiveColor px-3 py-[4px] text-[12px] font-[400] leading-4 text-white";
      "select-none rounded-[5px] bg-btnActiveColor px-3 py-[4px] text-[12px] font-[400] leading-4 text-white";
    this.inactiveButtonStyle =
      // "cursor-pointer select-none rounded-[5px] px-[20px] py-[4px] text-[12px] font-[400] leading-4 hover:bg-btnActiveColor/50 hover:text-white";
      // "cursor-pointer select-none rounded-[5px] px-3 py-[4px] text-[12px] font-[400] leading-4 hover:bg-btnActiveColor/50 hover:text-white";
      "select-none rounded-[5px] px-3 py-[4px] text-[12px] font-[400] leading-4 hover:bg-btnActiveColor/50 hover:text-white";
    this.activeButton = "Announcement-IPOs";
  }

  async init() {
    // const isOk = await validateToken(this.config.token, this.mainContainer);
    // if (!isOk) {
    //   return;
    // }
    await this.fetchData();
    this.showContent();
  }

  async fetchData() {
    try {
      await isMoblie({ encyryptedText: this.config.token });
      const data = await fetch(`${NPSTOCKS_LIVE_URL}/widget/announcements`);
      const responseData = await data.json();
      this.data = responseData.response;
      this.fetchError = false;
    } catch (e) {
      console.log(e);
      this.fetchError = true;
    }
  }

  showContent() {
    this.mainContainer.classList.add(
      "h-[739px]",
      "overflow-hidden",
      "rounded-md",
      "bg-white",
      "dark:bg-dark",
      "dark:text-white",
      "m-2"
    );
    const bodyContainer = document.createElement("div");
    bodyContainer.id = "Announcement-Body-Container";
    bodyContainer.classList.add(
      "max-h-[679px]",
      "overflow-y-auto",
      "xsm:scrollbar",
      "dark:text-darkText"
    );
    this.mainContainer.append(this.header(this.activeButton), bodyContainer);
    if (this.fetchError) {
      this.errorContainer({ calledFrom: "" });
    } else {
      bodyContainer.append(this.ipo());
    }
  }

  errorContainer({ calledFrom = "" }) {
    const bodyContainer = document.getElementById(
      "Announcement-Body-Container"
    );
    bodyContainer.classList.add(
      "h-[679px]",
      "flex",
      "justify-center",
      "items-center"
    );
    bodyContainer.innerHTML =
      calledFrom === ""
        ? `<div class="dark:text-white/50 text-black/50">
    Sorry, an error occured while fetching data.
  </div>`
        : `<div class="dark:text-white/50 text-black/50">
  Sorry, No Data Found.
</div>`;
    // this.mainContainer.append(bodyContainer);
  }

  header(activeButton) {
    const container = document.createElement("div");
    container.classList.add(
      "border-b",
      "p-[10px]",
      "py-[9px]",
      "dark:border-white/20"
    );
    const tabsContainer = document.createElement("div");
    tabsContainer.classList.add(
      "mt-[7px]",
      "flex",
      "items-center",
      "justify-between",
      "overflow-auto",
      "sm:justify-start",
      "sm:gap-5"
    );
    tabsContainer.id = "Announcement-tabs";
    const ipo = document.createElement("div");
    ipo.id = "Announcement-IPOs";
    ipo.innerText = "IPOs";
    ipo.className = this.activeButtonStyle;
    ipo.onclick = () => {
      this.activeButton = ipo.id;
      this.tabChanger(this.activeButton);
    };
    const dividend = document.createElement("div");
    dividend.id = "Announcement-Dividend";
    dividend.innerText = "Dividend";
    dividend.className = this.inactiveButtonStyle;
    dividend.onclick = () => {
      this.activeButton = dividend.id;
      this.tabChanger(this.activeButton);
    };
    const rights = document.createElement("div");
    rights.id = "Announcement-Rights";
    rights.innerText = "Rights";
    rights.className = this.inactiveButtonStyle;
    rights.onclick = () => {
      this.activeButton = rights.id;
      this.tabChanger(this.activeButton);
    };
    const auction = document.createElement("div");
    auction.id = "Announcement-Auction";
    auction.innerText = "Auction";
    auction.className = this.inactiveButtonStyle;
    auction.onclick = () => {
      this.activeButton = auction.id;
      this.tabChanger(this.activeButton);
    };
    const agm = document.createElement("div");
    agm.id = "Announcement-AGM";
    agm.innerText = "AGM";
    agm.className = this.inactiveButtonStyle;
    agm.onclick = () => {
      this.activeButton = agm.id;
      this.tabChanger(this.activeButton);
    };

    tabsContainer.append(ipo, dividend, rights, auction, agm);

    container.append(tabsContainer);
    return container;
  }

  ipo() {
    const container = document.createElement("div");
    const titleContainer = document.createElement("div");
    titleContainer.className =
      "my-2 grid grid-cols-[.7fr,.32fr,.32fr;] pt-[5px] pl-[20px] pr-[17px]";
    const gridElementOne = document.createElement("div");
    const gridElementTwo = document.createElement("div");
    gridElementTwo.className =
      "text-[10px] font-[400] leading-3 text-titleLabelDarkText";
    gridElementTwo.innerText = "Opening Date";
    const gridElementThree = document.createElement("div");
    gridElementThree.className =
      "text-right text-[10px] font-[400] leading-3 text-titleLabelDarkText";
    gridElementThree.innerText = "Closing Date";
    titleContainer.append(gridElementOne, gridElementTwo, gridElementThree);
    container.append(titleContainer);
    for (let data of this.data.ipo) {
      container.append(
        this.ipoElements(data.company, data.openingDate, data.closingDate),
        this.ipoSubElements(
          data.company,
          data.openingDate,
          data.closingDate,
          data.details,
          data.shareRegistrar
        )
      );
    }
    return container;
  }

  ipoElements(name, openDate, closeDate) {
    const container = document.createElement("div");
    container.className =
      // "cursor-pointer border-b border-lightBorderColor dark:border-darkBorderColor py-2 pl-[20px] pr-[17px] hover:bg-lightHoverColor dark:hover:bg-slate-800";
      "border-b border-lightBorderColor dark:border-darkBorderColor py-2 pl-[20px] pr-[17px] hover:bg-lightHoverColor dark:hover:bg-slate-800";
    const elementsContainer = document.createElement("div");
    elementsContainer.className =
      "grid grid-cols-[.7fr,.32fr,.32fr;] items-center gap-1 py-[8px]";
    const nameContainer = document.createElement("div");
    nameContainer.className =
      "text-[12px] font-[400] leading-4 text-clickAbleLinkColor dark:text-cyan-500";
    nameContainer.innerText = name ?? "-";
    const openDateContainer = document.createElement("div");
    openDateContainer.className =
      "text-[10px] xsm:text-[12px] font-[400] leading-4 xsm:leading-4";
    openDateContainer.innerText = openDate ?? "-";
    const closeDateContainer = document.createElement("div");
    closeDateContainer.className =
      "text-right text-[10px] xsm:text-[12px] font-[400] leading-4 xsm:leading-4";
    closeDateContainer.innerText = closeDate ?? "-";
    elementsContainer.append(
      nameContainer,
      openDateContainer,
      closeDateContainer
    );
    container.append(elementsContainer);
    container.onclick = () => {
      const subElement = document.getElementById(name + openDate + closeDate);
      if (subElement.classList.contains("hidden")) {
        subElement.classList.remove("hidden");
        container.classList.add("bg-lightHoverColor", "dark:bg-slate-800");
      } else {
        container.classList.remove("bg-lightHoverColor", "dark:bg-slate-800");
        subElement.classList.add("hidden");
      }
    };
    return container;
  }

  ipoSubElements(name, openDate, closeDate, details, shareRegistrarName) {
    const container = document.createElement("div");
    container.className =
      "border-b border-lightBorderColor dark:border-darkBorderColor py-4 pl-[18px] pr-[25px] hidden";
    container.id = name + openDate + closeDate;

    const detailsContainer = document.createElement("div");
    detailsContainer.className = "grid grid-cols-[2fr,2.5fr;]";

    const detailName = document.createElement("div");
    detailName.className =
      "text-[10px] font-[400] leading-4 text-titleLabelDarkText";
    detailName.innerText = "DETAILS";

    const detailValueContainer = document.createElement("div");

    for (let data of details.split("##")) {
      const information = document.createElement("div");
      information.classList.add("text-[12px]", "font-[500]", "leading-4");
      information.innerText = data;
      detailValueContainer.append(information);
    }

    detailsContainer.append(detailName, detailValueContainer);

    const shareRegistrarContainer = document.createElement("div");
    shareRegistrarContainer.className =
      "mt-4 grid grid-cols-[2fr,2.5fr;] items-start";
    const shareRegistrar = document.createElement("div");
    shareRegistrar.className =
      "text-[10px] font-[400] leading-4 text-titleLabelDarkText";
    shareRegistrar.innerText = "SHARE REGISTRAR";
    const shareRegistarName = document.createElement("div");
    shareRegistarName.className = "text-[12px] font-[500] leading-4";
    shareRegistarName.innerText = shareRegistrarName ?? "-";
    shareRegistrarContainer.append(shareRegistrar, shareRegistarName);
    container.append(detailsContainer, shareRegistrarContainer);
    return container;
  }

  auction() {
    const container = document.createElement("div");
    const titleContainer = document.createElement("div");
    titleContainer.className =
      "xsm:grid-cols-[1.5fr,1.5fr,1.5fr,2fr;] my-2 grid grid-cols-3 pt-[5px] pl-[20px] pr-[17px]";
    const gridElementOne = document.createElement("div");
    const gridElementTwo = document.createElement("div");
    gridElementTwo.className =
      "text-[10px] font-[400] leading-3 text-titleLabelDarkText";
    gridElementTwo.innerText = "Opening Date";
    const gridElementThree = document.createElement("div");
    gridElementThree.className =
      "text-[10px] font-[400] leading-3 text-titleLabelDarkText";
    gridElementThree.innerText = "Closing Date";
    const gridElementFour = document.createElement("div");
    gridElementFour.className =
      "xsm:block hidden text-[10px] font-[400] leading-3 text-titleLabelDarkText";
    gridElementFour.innerText = "Type";
    titleContainer.append(
      gridElementOne,
      gridElementTwo,
      gridElementThree,
      gridElementFour
    );
    container.append(titleContainer);
    for (let data of this.data.auction) {
      container.append(
        this.auctionElements(
          data.ticker,
          data.openingDate,
          data.closingDate,
          data.details
        ),
        this.auctionSubElements(
          data.ticker,
          data.openingDate,
          data.closingDate,
          data.agenda,
          data.shareRegistrar,
          data.ticker
        )
      );
    }
    return container;
  }

  auctionElements(name, openDate, closeDate, details) {
    const container = document.createElement("div");
    container.className =
      // "cursor-pointer border-b border-lightBorderColor dark:border-darkBorderColor py-2 pl-[20px] pr-[17px] hover:bg-lightHoverColor dark:hover:bg-slate-800";
      "border-b border-lightBorderColor dark:border-darkBorderColor py-2 pl-[20px] pr-[17px] hover:bg-lightHoverColor dark:hover:bg-slate-800";
    const elementsContainer = document.createElement("div");
    elementsContainer.className =
      "xsm:grid-cols-[1.5fr,1.5fr,1.5fr,2fr;] grid grid-cols-3 items-center gap-1 py-[8px]";
    const nameContainer = document.createElement("div");
    nameContainer.className =
      "text-[12px] font-[400] leading-4 text-clickAbleLinkColor dark:text-cyan-500";
    nameContainer.innerText = name ?? "-";
    const openDateContainer = document.createElement("div");
    openDateContainer.className = "text-[12px] font-[400] leading-4";
    openDateContainer.innerText = openDate ?? "-";
    const closeDateContainer = document.createElement("div");
    closeDateContainer.className = "text-[12px] font-[400] leading-4";
    closeDateContainer.innerText = closeDate ?? "-";
    const typeContainer = document.createElement("div");
    typeContainer.className =
      "xsm:block hidden text-[12px] font-[400] leading-4";
    typeContainer.innerText = details ?? "-";
    elementsContainer.append(
      nameContainer,
      openDateContainer,
      closeDateContainer,
      typeContainer
    );
    container.append(elementsContainer);
    container.onclick = () => {
      const subElement = document.getElementById(name + openDate + closeDate);
      if (subElement.classList.contains("hidden")) {
        subElement.classList.remove("hidden");
        container.classList.add("bg-lightHoverColor", "dark:bg-slate-800");
      } else {
        container.classList.remove("bg-lightHoverColor", "dark:bg-slate-800");
        subElement.classList.add("hidden");
      }
    };
    return container;
  }

  auctionSubElements(name, openDate, closeDate, details, shareRegistrarName) {
    const container = document.createElement("div");
    container.className =
      "border-b border-lightBorderColor dark:border-darkBorderColor py-4 pl-[18px] pr-[25px] hidden";
    container.id = name + openDate + closeDate;

    const detailsContainer = document.createElement("div");
    detailsContainer.className = "grid grid-cols-[1fr,2.5fr;]";

    const detailName = document.createElement("div");
    detailName.className =
      "text-[12px] font-[400] leading-4 text-titleLabelDarkText";
    detailName.innerText = "DETAILS";

    const detailValueContainer = document.createElement("div");

    for (let data of details.split("##")) {
      const information = document.createElement("div");
      information.classList.add("text-[12px]", "font-[500]", "leading-4");
      information.innerText = data;
      detailValueContainer.append(information);
    }

    detailsContainer.append(detailName, detailValueContainer);

    const shareRegistrarContainer = document.createElement("div");
    shareRegistrarContainer.className =
      "mt-4 grid grid-cols-[1fr,2.5fr;] items-start";
    const shareRegistrar = document.createElement("div");
    shareRegistrar.className =
      "text-[12px] font-[400] leading-4 text-titleLabelDarkText";
    shareRegistrar.innerText = "SHARE REGISTRAR";
    const shareRegistarName = document.createElement("div");
    shareRegistarName.className = "text-[12px] font-[500] leading-4";
    shareRegistarName.innerText = shareRegistrarName ?? "-";
    shareRegistrarContainer.append(shareRegistrar, shareRegistarName);
    container.append(detailsContainer, shareRegistrarContainer);
    return container;
  }

  agm() {
    const container = document.createElement("div");
    const titleContainer = document.createElement("div");
    titleContainer.className =
      "xsm:grid-cols-[1.5fr,1.5fr,1.5fr,2fr;] my-2 grid grid-cols-3 pt-[5px] pl-[20px] pr-[17px]";
    const gridElementOne = document.createElement("div");
    const gridElementTwo = document.createElement("div");
    gridElementTwo.className =
      "text-[10px] font-[400] leading-3 text-titleLabelDarkText";
    gridElementTwo.innerText = "Bookclose Date";
    const gridElementThree = document.createElement("div");
    gridElementThree.className =
      "text-[10px] font-[400] leading-3 text-titleLabelDarkText";
    gridElementThree.innerText = "Event Date";
    const gridElementFour = document.createElement("div");
    gridElementFour.className =
      "xsm:block hidden text-[10px] font-[400] leading-3 text-titleLabelDarkText";
    gridElementFour.innerText = "Details";
    titleContainer.append(
      gridElementOne,
      gridElementTwo,
      gridElementThree,
      gridElementFour
    );
    container.append(titleContainer);
    for (let data of this.data.agm) {
      container.append(
        this.agmElements(
          data.ticker,
          data.bookCloseDate,
          data.eventDate,
          data.details
        ),
        this.agmSubElements(
          data.ticker,
          data.bookCloseDate,
          data.eventDate,
          data.moreDetails,
          data.time,
          data.venue,
          data.fiscalYear
        )
      );
    }
    return container;
  }

  agmElements(name, openDate, closeDate, details) {
    const container = document.createElement("div");
    container.className =
      // "cursor-pointer border-b border-lightBorderColor dark:border-darkBorderColor py-2 pl-[20px] pr-[17px] hover:bg-[#F2F3F5] dark:hover:bg-slate-800";
      "border-b border-lightBorderColor dark:border-darkBorderColor py-2 pl-[20px] pr-[17px] hover:bg-[#F2F3F5] dark:hover:bg-slate-800";
    const elementsContainer = document.createElement("div");
    elementsContainer.className =
      "xsm:grid-cols-[1.5fr,1.5fr,1.5fr,2fr;] grid grid-cols-3 items-center gap-1 py-[8px]";
    const nameContainer = document.createElement("div");
    nameContainer.className =
      "text-[12px] font-[400] leading-4 text-clickAbleLinkColor dark:text-cyan-500";
    nameContainer.innerText = name ?? "-";
    const openDateContainer = document.createElement("div");
    openDateContainer.className = "text-[12px] font-[400] leading-4";
    openDateContainer.innerText = openDate ?? "-";
    const closeDateContainer = document.createElement("div");
    closeDateContainer.className = "text-[12px] font-[400] leading-4";
    closeDateContainer.innerText = closeDate ?? "-";
    const typeContainer = document.createElement("div");
    typeContainer.className =
      "xsm:block hidden text-[12px] font-[400] leading-4";
    typeContainer.innerText = details ?? "-";
    elementsContainer.append(
      nameContainer,
      openDateContainer,
      closeDateContainer,
      typeContainer
    );
    container.append(elementsContainer);
    container.onclick = () => {
      const subElement = document.getElementById(name + openDate + closeDate);
      if (subElement.classList.contains("hidden")) {
        subElement.classList.remove("hidden");
        container.classList.add("bg-lightHoverColor", "dark:bg-slate-800");
      } else {
        container.classList.remove("bg-lightHoverColor", "dark:bg-slate-800");
        subElement.classList.add("hidden");
      }
    };
    return container;
  }

  agmSubElements(
    name,
    openDate,
    closeDate,
    details,
    shareRegistrarName,
    venueValue,
    fiscalYear
  ) {
    const container = document.createElement("div");
    container.className =
      "border-b border-lightBorderColor dark:border-darkBorderColor py-4 pl-[18px] pr-[25px] hidden";
    container.id = name + openDate + closeDate;

    const fiscalContainer = document.createElement("div");
    fiscalContainer.className = "mt-1 grid grid-cols-[1fr,2.5fr;] items-start";

    const fiscalYearStr = document.createElement("div");
    fiscalYearStr.className =
      "text-[12px] font-[400] leading-4 text-titleLabelDarkText";
    fiscalYearStr.innerText = "FISCAL YEAR";

    const fiscalYearValue = document.createElement("div");
    fiscalYearValue.className = "text-[12px] font-[500] leading-4";
    fiscalYearValue.innerText = fiscalYear ?? "-";

    fiscalContainer.append(fiscalYearStr, fiscalYearValue);

    const detailsContainer = document.createElement("div");
    detailsContainer.className = "mt-4 grid grid-cols-[1fr,2.5fr;] items-start";

    const detailName = document.createElement("div");
    detailName.className =
      "text-[12px] font-[400] leading-4 text-titleLabelDarkText";
    detailName.innerText = "DETAILS";

    const detailValueContainer = document.createElement("div");

    for (let data of details.split("##")) {
      const information = document.createElement("div");
      information.classList.add("text-[12px]", "font-[500]", "leading-4");
      information.innerText = data;
      detailValueContainer.append(information);
    }

    detailsContainer.append(detailName, detailValueContainer);

    const shareRegistrarContainer = document.createElement("div");
    shareRegistrarContainer.className =
      "mt-4 grid grid-cols-[1fr,2.5fr;] items-start";
    const shareRegistrar = document.createElement("div");
    shareRegistrar.className =
      "text-[12px] font-[400] leading-4 text-titleLabelDarkText";
    shareRegistrar.innerText = "TIME";
    const shareRegistarName = document.createElement("div");
    shareRegistarName.className = "text-[12px] font-[500] leading-4";
    shareRegistarName.innerText = shareRegistrarName ?? "-";
    shareRegistrarContainer.append(shareRegistrar, shareRegistarName);

    const venueContainer = document.createElement("div");
    venueContainer.className = "mt-4 grid grid-cols-[1fr,2.5fr;] items-start";
    const venue = document.createElement("div");
    venue.className =
      "text-[12px] font-[400] leading-4 text-titleLabelDarkText";
    venue.innerText = "VENUE";
    const venueName = document.createElement("div");
    venueName.className = "text-[12px] font-[500] leading-4";
    venueName.innerText = venueValue ?? "-";
    venueContainer.append(venue, venueName);
    container.append(
      fiscalContainer,
      detailsContainer,
      shareRegistrarContainer,
      venueContainer
    );
    return container;
  }

  tabChanger(tabClass) {
    const bodyContainer = document.getElementById(
      "Announcement-Body-Container"
    );
    bodyContainer.innerHTML = "";
    const buttons = [
      "Announcement-IPOs",
      "Announcement-Dividend",
      "Announcement-Rights",
      "Announcement-Auction",
      "Announcement-AGM",
    ];

    for (let id of buttons) {
      const btn = document.getElementById(id);
      if (tabClass === id) {
        btn.className = this.activeButtonStyle;
      } else {
        btn.className = this.inactiveButtonStyle;
      }
    }

    if (this.fetchError) {
      this.errorContainer({ calledFrom: "" });
    } else {
      if (tabClass === "Announcement-IPOs") {
        bodyContainer.append(this.ipo());
      } else if (tabClass === "Announcement-Dividend") {
        bodyContainer.append(this.dividend());
      } else if (tabClass === "Announcement-Rights") {
        bodyContainer.append(this.rights());
      } else if (tabClass === "Announcement-Auction") {
        bodyContainer.append(this.auction());
      } else if (tabClass === "Announcement-AGM") {
        bodyContainer.append(this.agm());
      }
    }
  }

  dividend() {
    const container = document.createElement("div");
    const titleContainer = document.createElement("div");
    titleContainer.className =
      // "my-2 grid grid-cols-[1fr,1fr,1fr,1.3fr,1.2fr] pt-[5px] pl-[20px] pr-[17px] xsm:grid-cols-[1fr,1fr,1fr,1fr,1fr,.9fr;]";
      "my-2 grid grid-cols-[.8fr,.8fr,.8fr,1.3fr,1.2fr] pt-[5px] pl-[20px] pr-[17px] xsm:grid-cols-[1fr,1fr,1fr,1fr,1fr,.9fr;]";
    const title = ["", "Bonus", "Cash", "Total", "Bookclose", "Fiscal Year"];
    for (let data of title) {
      titleContainer.append(this.titles(data, data === "Total"));
    }
    container.append(titleContainer);
    for (let data of this.data.dividend) {
      container.append(
        this.dividendElements(
          data.ticker,
          data.bonus,
          data.cash,
          data.total,
          data.bookCloseDate,
          data.fiscalYear
        )
      );
    }
    return container;
  }

  dividendElements(symbol, bonus, cash, total, bookClose, fiscalYear) {
    const container = document.createElement("div");
    container.className =
      // "cursor-pointer border-b border-lightBorderColor dark:border-darkBorderColor py-2 pl-[20px] pr-[17px] hover:bg-lightHoverColor dark:hover:bg-slate-800";
      "border-b border-lightBorderColor dark:border-darkBorderColor py-2 pl-[20px] pr-[17px] hover:bg-lightHoverColor dark:hover:bg-slate-800";
    const elementContainer = document.createElement("div");
    elementContainer.className =
      // "grid grid-cols-[1fr,1fr,1fr,1.3fr,1.2fr] items-center gap-1 py-[8px] xsm:grid-cols-[1fr,1fr,1fr,1fr,1fr,.9fr;]";
      "grid grid-cols-[.8fr,.8fr,.8fr,1.3fr,1.2fr] items-center gap-1 py-[8px] xsm:grid-cols-[1fr,1fr,1fr,1fr,1fr,.9fr;]";
    const symbolContainer = document.createElement("div");
    symbolContainer.className =
      "text-[12px] font-[400] leading-4 text-clickAbleLinkColor dark:text-cyan-500";
    symbolContainer.innerText = symbol ?? "-";
    const bonusContainer = document.createElement("div");
    bonusContainer.innerText =
      bonus === null ? "-" : commaSeperatedFloats(bonus) + "%";
    const cashContainer = document.createElement("div");
    cashContainer.innerText =
      cash === null ? "-" : commaSeperatedFloats(cash) + "%";
    const totalContainer = document.createElement("div");
    totalContainer.innerText =
      total === null ? "-" : commaSeperatedFloats(total) + "%";
    const bookCloseContainer = document.createElement("div");
    bookCloseContainer.innerText = bookClose ?? "-";
    const fiscalYearContainer = document.createElement("div");
    fiscalYearContainer.innerText = fiscalYear ?? "-";

    bonusContainer.className = cashContainer.className =
      "text-[12px] font-[400] leading-4";
    fiscalYearContainer.className = bookCloseContainer.className =
      "text-[10px] xsm:text-[12px] font-[400] leading-4";
    totalContainer.className =
      "hidden text-[12px] font-[400] leading-4 xsm:block";
    elementContainer.append(
      symbolContainer,
      bonusContainer,
      cashContainer,
      totalContainer,
      bookCloseContainer,
      fiscalYearContainer
    );
    container.append(elementContainer);
    return container;
  }

  rights() {
    const container = document.createElement("div");
    const titleContainer = document.createElement("div");
    titleContainer.className =
      "my-2 grid grid-cols-[.8fr,1fr,1fr,1fr,1fr] pt-[5px] pl-[20px] pr-[17px]";
    const title = ["", "Rights", "Bookclose", "Open Date", "Closing Date"];
    for (let data of title) {
      titleContainer.append(this.titles(data, data === "Total"));
    }
    container.append(titleContainer);
    for (let data of this.data.rights) {
      container.append(
        this.rightElements(
          data.ticker,
          // data.bonus,
          0.0,
          data.bookCloseDate,
          data.openingDate,
          data.closingDate
        )
      );
    }
    return container;
  }

  rightElements(symbol, rights, bookCloseDate, openingDate, closingDate) {
    const container = document.createElement("div");
    container.className =
      // "cursor-pointer border-b border-lightBorderColor dark:border-darkBorderColor py-2 pl-[20px] pr-[17px] hover:bg-lightHoverColor dark:hover:bg-slate-800";
      "border-b border-lightBorderColor dark:border-darkBorderColor py-2 pl-[20px] pr-[17px] hover:bg-lightHoverColor dark:hover:bg-slate-800";
    const elementContainer = document.createElement("div");
    elementContainer.className =
      "grid grid-cols-[.8fr,1fr,1fr,1fr,1fr] items-center gap-1 py-[8px]";
    const symbolContainer = document.createElement("div");
    symbolContainer.className =
      "text-[11px] xsm:text-[12px] font-[400] leading-4 text-clickAbleLinkColor dark:text-cyan-500";
    symbolContainer.innerText = symbol ?? "-";
    const bonusContainer = document.createElement("div");
    bonusContainer.innerText =
      rights === null ? "-" : commaSeperatedFloats(rights) + "%";
    const cashContainer = document.createElement("div");
    cashContainer.innerText = bookCloseDate ?? "-";
    const totalContainer = document.createElement("div");
    totalContainer.innerText = openingDate ?? "-";
    const bookCloseContainer = document.createElement("div");
    bookCloseContainer.innerText = closingDate ?? "-";

    bonusContainer.className =
      "text-[12px] xsm:text-[12px] font-[400] leading-4";
    totalContainer.className =
      cashContainer.className =
      bookCloseContainer.className =
        "text-[10px] xsm:text-[12px] font-[400] leading-4";
    elementContainer.append(
      symbolContainer,
      bonusContainer,
      cashContainer,
      totalContainer,
      bookCloseContainer
    );
    container.append(elementContainer);
    return container;
  }

  titles(id, isHidden) {
    const hiddenStyle =
      "hidden text-[10px] font-[400] leading-3 text-titleLabelDarkText xsm:block";
    const normalStyle =
      "text-[10px] font-[400] leading-3 text-titleLabelDarkText";
    const container = document.createElement("div");
    if (isHidden) {
      container.className = hiddenStyle;
      container.innerText = id;
      return container;
    }
    container.className = normalStyle;
    container.innerText = id;
    return container;
  }
}
