import { isMoblie, NPSTOCKS_LIVE_URL } from "../Utils";
import * as LightweightCharts from "lightweight-charts";

export default class ChartWidgetSmall {
  constructor({
    token,
    id,
    tiker,
    period = "1D",
    // width = 333,
    // height = 189,
    width = window.innerWidth * 0.9,
    height = window.innerHeight * 0.2,
    lineWidth = 2,
    topColor = "rgba(33, 150, 243, 0.56)",
    bottomColor = "rgba(33, 150, 243, 0.04)",
    lineColor = "rgba(33, 150, 243, 1)",
    theme = "light",
  }) {
    this.config = {
      token,
      id,
      tiker,
      period,
      width,
      height,
      lineWidth,
      topColor,
      bottomColor,
      lineColor,
      theme,
    };
    this.mainContainer = document.querySelector(id);
    this.mainContainer.innerHTML = "";
    this.style = document.createElement("style");
    this.style.innerHTML = `@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
            ${id}{font-family: 'Inter', sans-serif;}`;
    this.mainContainer.appendChild(this.style);
    this.data = {};
    this.activeTab = "1D";
    // this.mainContainer.classList.add("border-2", "p-2");
  }

  async fetchData() {
    if (this.data["1D"] !== undefined) {
      // console.log(this.data, "data")
      return;
    }
    try {
      await isMoblie({ encyryptedText: this.config.token });
      const liveData = await fetch(
        `${NPSTOCKS_LIVE_URL}/widget/timeseries-tradingview?period=${this.config.period}&ticker=${this.config.tiker}`
      );
      const tempdata = await liveData.json();
      this.data["1D"] = tempdata.response;
      // // const liveData = await fetch(
      // //   `${NPSTOCKS_LIVE_URL}/widget/timeseries-tradingview?period=${this.config.period}&ticker=${this.config.tiker}`
      // // );
      // // const tempdata = await liveData.json();
      // // this.data = tempdata.response;
      // this.data["1D"] = oneDay;
      // this.data["1M"] = oneMonth;
      // this.data["6M"] = sixMonth;
      // this.data["1Y"] = oneYear;
      // this.data["5Y"] = fiveYear;
      // this.data["All"] = allData;
    } catch (e) {
      console.log(e);
    }
  }

  async init() {
    await this.fetchData();
    this.renderContent();
    await this.afterFetch();
  }

  async afterFetch() {
    try {
      const rangeElements = ["1M", "6M", "1Y", "5Y", "All"];
      for (let element of rangeElements) {
        if (this.data[element] !== undefined) {
          continue;
        }
        const liveData = await fetch(
          `${NPSTOCKS_LIVE_URL}/widget/timeseries-tradingview?period=${element}&ticker=${this.config.tiker}`
        );
        const tempdata = await liveData.json();
        this.data[element] = tempdata.response;
      }
    } catch (e) {
      console.log(e);
    }
  }

  renderContent() {
    this.displayChart();
  }

  displayChart() {
    // this.mainContainer.classList.add("m-2", "rounded-md");
    var chartElement = document.createElement("div");
    chartElement.id = "chart-small-root-div";
    // chartElement.classList.add("border-2", "p-4");
    // chartElement.classList.add("relative", "flex", "flex-col", "space-y-2", "min-w-min");
    chartElement.classList.add(
      "relative",
      "flex",
      "flex-col",
      "min-w-min",
      "p-3",
      "rounded-md",
      "m-2"
    );
    if (this.config.theme === "dark") {
      chartElement.classList.add("bg-[#1E222D]", "text-white");
    } else {
      chartElement.classList.add("bg-white");
    }
    // const rangeElements = ["1D", "1M", "6M", "1Y", "5Y", "All"];
    const rangeElements = ["1D", "1M", "6M", "1Y", "5Y"];
    const tabContainer = document.createElement("ul");
    // tabContainer.classList.add("flex", "space-x-1");
    tabContainer.classList.add("flex", "space-x-1", "mt-4");
    for (let element of rangeElements) {
      if (element === this.activeTab) {
        tabContainer.append(this.tabs(element, true));
        continue;
      }
      tabContainer.append(this.tabs(element));
    }
    // chartElement.append(tabContainer);
    var chart = LightweightCharts.createChart(chartElement, {
      width: this.config.width,
      height: this.config.height,
      layout: {
        // fontFamily: "Comic Sans MS",
        backgroundColor:
          this.config.theme == "dark" ? "rgba(30,34,45,1)" : null,
        fontFamily: "Inter",
        textColor: this.config.theme == "dark" ? "rgba(255,255,255,.8)" : null,
      },
      rightPriceScale: {
        visible: false,
        // borderColor: "rgba(197, 203, 206, 1)",
        borderColor: "rgba(197, 203, 206, 0)",
      },
      grid: {
        vertLines: {
          visible: false,
        },
        horzLines: {
          visible: false,
          color:
            this.config.theme == "dark"
              ? "rgba(255,255,255,.05)"
              : "rgba(0, 0, 0, 0.025)",
        },
      },
      timeScale: {
        visible: false,
        timeVisible: this.activeTab === "1D",
        secondsVisible: false,
        borderVisible: false,
      },
      crosshair: {
        horzLine: {
          visible: false,
          labelVisible: false,
        },
        vertLine: {
          visible: true,
          style: 0,
          width: 2,
          labelVisible: false,
          color: "rgba(33, 150, 243, 0.2)",
        },
      },
      handleScale: false,
    });
    chartElement.append(tabContainer);
    this.mainContainer.append(chartElement);

    var areaSeries = chart.addAreaSeries({
      priceLineVisible: false,
      topColor: this.config.topColor,
      bottomColor: this.config.bottomColor,
      lineColor: this.config.lineColor,
      lineWidth: this.config.lineWidth,
    });

    chart.timeScale().fitContent();
    // var toolTipWidth = 100;
    // var toolTipHeight = 80;
    // var toolTipMargin = 15;
    var toolTipWidth = 50;
    var toolTipHeight = 40;
    var toolTipMargin = 15;

    let tiker = this.config.tiker;
    let width = this.config.width;
    let height = this.config.height;

    var toolTip = document.createElement("div");
    toolTip.className = "floating-tooltip-2 absolute";
    chartElement.appendChild(toolTip);

    chart.subscribeCrosshairMove(function (param) {
      if (
        !param.time ||
        param.point.x < 0 ||
        param.point.x > width ||
        param.point.y < 0 ||
        param.point.y > height
      ) {
        toolTip.style.display = "none";
        return;
      }
      toolTip.style.display = "block";
      toolTip.style.position = "absolute";
      toolTip.style.zIndex = 10;
      var price = param.seriesPrices.get(areaSeries);
      toolTip.innerHTML =
        `<div style="font-size:16px">${tiker}</div>` +
        '<div style="font-size: 14px; margin: 0px 0px; font-weight: 600">' +
        (Math.round(price * 100) / 100).toFixed(2) +
        "</div>";

      var coordinate = areaSeries.priceToCoordinate(price);
      var shiftedCoordinate = param.point.x - 0;
      if (coordinate === null) {
        return;
      }
      shiftedCoordinate = Math.max(
        0,
        Math.min(width - toolTipWidth, shiftedCoordinate)
      );
      var coordinateY =
        coordinate - toolTipHeight - toolTipMargin > 0
          ? coordinate - toolTipHeight - toolTipMargin
          : Math.max(
              0,
              Math.min(
                height - toolTipHeight - toolTipMargin,
                coordinate + toolTipMargin
              )
            );
      toolTip.style.left = shiftedCoordinate + "px";
      toolTip.style.top = coordinateY + "px";
    });
    // console.log("Intraday", oneDay)
    areaSeries.setData(this.data[this.activeTab]);
    // areaSeries.setData(oneDay);
    window.addEventListener("resize", () => {
      // chart.resize(window.innerWidth * 0.19, window.innerHeight * 0.21);
      chart.resize(window.innerWidth * 0.9, window.innerHeight * 0.2);
    });
  }

  tabs(text, isActive = false) {
    const liContainer = document.createElement("li");
    liContainer.innerText = text;
    liContainer.classList.add(
      "cursor-pointer",
      "px-4",
      "font-[600]",
      "text-[14px]"
    );
    if (isActive) {
      liContainer.classList.add(
        "underline",
        "underline-offset-4",
        "decoration-2"
      );
      if (this.config.theme === "dark") {
        liContainer.classList.add("text-white");
      } else {
        liContainer.classList.add("text-black");
      }
    } else {
      if (this.config.theme === "dark") {
        liContainer.classList.add("text-white/[.5]", "hover:text-white/[.9]");
      } else {
        liContainer.classList.add("text-black/[.5]", "hover:text-black/[.8]");
      }
    }
    liContainer.onclick = () => {
      this.activeTab = liContainer.innerText;
      document.getElementById("chart-small-root-div").remove();
      this.init();
    };
    return liContainer;
  }
}
