import {NPSTOCKS_URL} from '../Utils';

export async function validateToken(token, container) {
    try {
        const data = await fetch(
            `${NPSTOCKS_URL}/validateUI?${new URLSearchParams({
                token,
            })}`,
        );
        const formattedData = await data.json();
        if (!formattedData.isValid) {
            // showErrorMessage(container);
            return true;
        }
        return true;
    } catch (e) {
        console.log(e);
        showErrorMessage(container);
        return true;
    }
}

export function showErrorMessage(documentContainer) {
    console.log(documentContainer);
    if (documentContainer) {
        console.warn('Token is not validated.');
        documentContainer.innerHTML = `
                 <div
  class="items-center gap-4 p-4 text-white bg-red rounded flex justify-center"
  role="alert"
>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="w-5 h-5 text-red-500"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    stroke-width="2"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M20.618 5.984A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016zM12 9v2m0 4h.01"
    />
  </svg>

  <strong class="text-sm font-normal uppercase">Unauthorized access !!</strong>
</div>
        `;
    }
}
