import {
  commaSeperatedFloats,
  formatNumber,
  isMoblie,
  isNegativeNumber,
  MONTHS,
  NPSTOCKS_LIVE_URL,
} from "../Utils";
import { createSpinner } from "../Utils/UI";

export default class SymbolInfo {
  constructor({ token, id, tiker }) {
    this.config = { token, id };
    this.tiker = tiker;
    this.mainContainer = document.querySelector(id);
    this.mainContainer.innerHTML = "";
    this.style = document.createElement("style");
    this.style.innerHTML = `@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
    ${id}{font-family: 'Inter', sans-serif;}`;
    this.mainContainer.appendChild(this.style);
    this.isNegative = false;
    this.fetchError = false;
  }

  async init() {
    await this.fetchData();
    this.renderContent();
  }

  async fetchData() {
    try {
      await isMoblie({ encyryptedText: this.config.token });
      const data = await fetch(
        `${NPSTOCKS_LIVE_URL}/widget/stockInfo?ticker=${this.tiker}`
      );
      this.apiData = await data.json();
      this.data = this.apiData.response;
      this.isNegative = isNegativeNumber(this.apiData.pointChange);
      this.fetchError = false;
    } catch (e) {
      console.log(e);
      this.fetchError = true;
    }
  }

  renderContent() {
    this.mainContainer.classList.add(
      "flex",
      "m-2",
      "h-[106px]",
      "w-[1028px]",
      "cursor-pointer",
      "overflow-hidden",
      "rounded-[5px]",
      "border-2",
      "bg-white",
      "pt-[11px]",
      "pb-[18px]",
      "pl-[23px]",
      "pr-[17px]",
      "dark:bg-dark",
      "dark:text-darkText"
    );
    if (this.fetchError) {
      const error = document.createElement("div");
      error.className =
        "dark:text-white/50 text-black/50 flex justify-center items-center h-full w-full";
      error.innerText = "Failed to fetch data.";
      this.mainContainer.append(error);
    } else {
      this.mainContainer.onclick = () => {
        const size = this.mainContainer.classList.contains("h-[106px]");
        if (this.mainContainer.classList.contains("h-[106px]")) {
          this.mainContainer.className =
            "flex h-[166px] m-2 w-[1028px] cursor-pointer overflow-hidden rounded-[5px] border-2 bg-white pt-[11px] pb-[18px] pl-[23px] pr-[17px] dark:bg-dark dark:text-darkText";
        } else {
          this.mainContainer.className =
            "flex h-[106px] m-2 w-[1028px] cursor-pointer overflow-hidden rounded-[5px] border-2 bg-white pt-[11px] pb-[18px] pl-[23px] pr-[17px] dark:bg-dark dark:text-darkText";
        }
      };
      this.mainContainer.append(
        this.positionLeftTikerInfo(
          commaSeperatedFloats(this.data.ltp),
          commaSeperatedFloats(this.data.percentageChange),
          commaSeperatedFloats(this.data.pointChange),
          this.data.dateTime
        ),
        this.positionMiddleDataContainer(),
        // this.positionRightBankInfo(this.data.tiker, this.data.name)
        this.positionRightBankInfo(this.data.symbol, this.data.company)
      );
    }
  }

  percentageAndPoint(percentage, points) {
    const containerDiv = document.createElement("div");
    containerDiv.classList.add("mt-[-3px]");
    if (!this.isNegative) {
      containerDiv.innerHTML = `<svg
          class="inline mb-[5px]"
          width="14"
          height="8"
          viewBox="0 0 14 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.5718 6.87439L6.74646 1.34877L1.43959 6.87439"
            stroke="#53A895"
            stroke-width="1.4"
          />
        </svg>`;
    } else {
      containerDiv.innerHTML = `<svg  class="inline mb-[5px]" width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.42847 1.12561L7.25378 6.65123L12.5607 1.12561" stroke="#E15562" stroke-width="1.4"/>
      </svg>`;
    }

    const percentageContainer = document.createElement("span");
    percentageContainer.classList.add(
      "text-[14px]",
      "font-[700]",
      "leading-[16.94px]"
    );
    const pointContainer = document.createElement("span");
    pointContainer.classList.add(
      "text-[14px]",
      "font-[700]",
      "leading-[16.94px]"
    );
    // percentageContainer.innerText = " " + percentage + "% ";
    // pointContainer.innerText = points;
    if (this.isNegative) {
      percentageContainer.classList.add("text-red");
      percentageContainer.innerText = " " + percentage + "% ";
      pointContainer.innerText = points;
      pointContainer.classList.add("text-red");
    } else {
      percentageContainer.classList.add("text-darkGreen");
      percentageContainer.innerText = " +" + percentage + "% ";
      pointContainer.innerText = "+" + points;
      pointContainer.classList.add("text-darkGreen");
    }
    containerDiv.append(percentageContainer, pointContainer);
    return containerDiv;
  }

  positionLeftTikerInfo(ltp, percentage, points, date) {
    const containerDiv = document.createElement("div");
    containerDiv.classList.add("basis-48");
    const price = document.createElement("div");
    price.classList.add("leading-[38.73px]");
    const priceSpan = document.createElement("span");
    priceSpan.classList.add("text-[32px]", "font-[700]");
    priceSpan.innerText = ltp;
    price.appendChild(priceSpan);

    const currentDate = new Date(date);

    function getZeroBeforeNum(num) {
      if (num > 9) {
        return num;
      }
      return "0" + num;
    }

    const dateString = `${
      MONTHS[currentDate.getMonth()]
    } ${currentDate.getDate()}, ${currentDate.getFullYear()} ${
      currentDate.getHours() > 12
        ? getZeroBeforeNum(Math.abs(12 - currentDate.getHours()))
        : getZeroBeforeNum(currentDate.getHours())
    }:${getZeroBeforeNum(currentDate.getMinutes())} ${
      currentDate.getHours() > 11 ? "PM" : "AM"
    }`;

    const dateDiv = document.createElement("div");
    dateDiv.classList.add(
      "block",
      "text-[10px]",
      "font-[400]",
      "leading-[12.1px]",
      "text-titleLabelDarkText",
      "mt-[5px]",
      "dark:text-secDarkText"
    );
    dateDiv.innerText = dateString;
    containerDiv.append(
      price,
      this.percentageAndPoint(percentage, points),
      dateDiv
    );
    return containerDiv;
  }

  positionRightBankInfo(tiker, tikerFullName) {
    const containerDiv = document.createElement("div");
    containerDiv.classList.add("basis-36", "text-right");
    console.log(containerDiv);
    const tikerNameSpan = document.createElement("span");
    tikerNameSpan.classList.add(
      "text-[24px]",
      "font-[600]",
      "leading-[29.05px]"
    );
    tikerNameSpan.innerText = tiker;
    const fullName = document.createElement("span");
    fullName.classList.add(
      "mt-[33px]",
      "block",
      "text-[14px]",
      "font-[400]",
      "leading-[16.94px]",
      "text-titleLabelDarkText",
      "dark:text-secDarkText"
    );
    fullName.innerText = tikerFullName;
    containerDiv.append(tikerNameSpan, fullName);
    return containerDiv;
  }

  positionMiddleDataContainer() {
    const containerDiv = document.createElement("div");
    containerDiv.classList.add("mt-[40px]", "flex-1");
    const gridContainer = document.createElement("div");
    gridContainer.classList.add("grid", "text-left");
    gridContainer.style =
      "grid-template-columns: 0.88fr 0.85fr 0.8fr 1.12fr .9fr 0.1fr";
    gridContainer.appendChild(
      this.individualIndicator(
        commaSeperatedFloats(this.data.volume),
        "VOLUME",
        commaSeperatedFloats(this.data.epsDiluted),
        "EPS dilluted"
      )
    );
    gridContainer.appendChild(
      this.individualIndicator(
        this.data.sharesTraded.toLocaleString(),
        "SHARES TRADED",
        commaSeperatedFloats(this.data.peDiluted),
        "PE dilluted"
      )
    );

    let marketCapString = undefined;
    if (this.data.marketCap > 1000000000000) {
      const marketCap = this.data.marketCap / 1000000000000;
      marketCapString = commaSeperatedFloats(marketCap) + " T";
    } else if (this.data.marketCap > 1000000000) {
      const marketCap = this.data.marketCap / 1000000000;
      marketCapString = commaSeperatedFloats(marketCap) + " B";
    } else if (this.data.marketCap > 1000000) {
      const marketCap = this.data.marketCap / 1000000;
      marketCapString = commaSeperatedFloats(marketCap) + " M";
    }
    gridContainer.appendChild(
      this.individualIndicator(
        marketCapString,
        "MARKET CAP",
        commaSeperatedFloats(this.data.pbv),
        // this.data.pbv.toFixed(2).toLocaleString(),
        "PBV"
      )
    );
    const dayRangeString =
      commaSeperatedFloats(this.data.dayLow) +
      " - " +
      commaSeperatedFloats(this.data.dayHigh);
    gridContainer.appendChild(
      this.individualIndicator(
        dayRangeString,
        "DAY RANGE",
        commaSeperatedFloats(this.data.roe) + "%",
        "ROE"
      )
    );
    const annualRangeString =
      commaSeperatedFloats(this.data.fiftyLow) +
      " - " +
      commaSeperatedFloats(this.data.fiftyHigh);
    gridContainer.appendChild(
      this.individualIndicator(
        annualRangeString,
        "52 WEEK RANGE",
        commaSeperatedFloats(this.data.roa) + "%",
        "ROA"
      )
    );

    const beta = document.createElement("div");
    beta.classList.add("mt-[63px]");
    const dataSpan = document.createElement("span");
    dataSpan.classList.add("text-[14px]", "font-[500]", "leading-[17px]");
    dataSpan.innerText = commaSeperatedFloats(this.data.beta);
    const dataSpanName = document.createElement("span");
    dataSpanName.classList.add(
      "block",
      "text-[10px]",
      "font-[400]",
      "leading-[12px]",
      "text-titleLabelDarkText",
      "mt-[7px]"
    );
    dataSpanName.innerText = "Beta";
    beta.append(dataSpan, dataSpanName);
    gridContainer.appendChild(beta);
    containerDiv.appendChild(gridContainer);
    return containerDiv;
  }

  individualIndicator(data, nameOfData, downValue, downNameofData) {
    const containerDiv = document.createElement("div");
    const value = document.createElement("span");
    value.classList.add("text-[14px]", "font-[500]", "leading-[17px]");
    value.innerText = data;
    const nameOfValue = document.createElement("span");
    nameOfValue.classList.add(
      "block",
      "text-[10px]",
      "font-[400]",
      "leading-[12px]",
      "text-titleLabelDarkText",
      "mt-[7px]",
      "dark:text-secDarkText"
    );
    nameOfValue.innerText = nameOfData;
    containerDiv.append(
      value,
      nameOfValue,
      this.showHideDiv(downValue, downNameofData)
    );
    return containerDiv;
  }
  showHideDiv(data, nameOfData) {
    const containerDiv = document.createElement("div");
    // containerDiv.id = "show-hide";
    containerDiv.className = "mt-[20px]";
    const value = document.createElement("span");
    value.classList.add("text-[14px]", "font-[500]", "leading-[17px]");
    value.innerText = data;
    const nameOfValue = document.createElement("span");
    nameOfValue.classList.add(
      "block",
      "text-[10px]",
      "font-[400]",
      "leading-[12px]",
      "text-titleLabelDarkText",
      "mt-[7px]",
      "dark:text-secDarkText"
    );
    nameOfValue.innerText = nameOfData;
    containerDiv.append(value, nameOfValue);
    return containerDiv;
  }
}
