import { validateToken } from "../Auth";
import {
  commaSeperatedFloats,
  getHours,
  isMoblie,
  isNegativeNumber,
  MONTHS,
  NPSTOCKS_LIVE_URL,
} from "../Utils";

export default class StockPriceTab {
  constructor({ token, id }) {
    this.config = { token, id };
    this.mainContainer = document.querySelector(id);
    this.mainContainer.innerHTML = "";
    this.style = document.createElement("style");
    this.style.innerHTML = `@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
                    ${id}{font-family: 'Inter', sans-serif;}`;
    this.mainContainer.appendChild(this.style);
    this.data = {};
    this.date = new Date();
    this.fetchError = false;
    this.selectedBtnStyle =
      // "cursor-pointer select-none rounded-[5px] bg-btnActiveColor px-[20px] py-[4px] text-[12px] font-[400] leading-[15px] text-white";
      "select-none rounded-[5px] bg-btnActiveColor px-[20px] py-[4px] text-[12px] font-[400] leading-[15px] text-white";
    this.unselectedBtnStyle =
      // "cursor-pointer select-none rounded-[5px] px-[20px] py-[4px] text-[12px] font-[400] leading-[15px] hover:bg-btnActiveColor/50 hover:text-white";
      "select-none rounded-[5px] px-[20px] py-[4px] text-[12px] font-[400] leading-[15px] hover:bg-btnActiveColor/50 hover:text-white";
    // this.headerElements = {
    //   ticker: "Ticker",
    //   ltp: "LTP",
    //   ltv: "LTV",
    //   pointChange: "Change",
    //   percentageChange: "Chg%",
    //   open: "Open",
    //   high: "High",
    //   low: "Low",
    //   qty: "Qty",
    // };
    this.headerElementsLables = [
      "Ticker",
      "LTP",
      "LTV",
      "Change",
      "Chg%",
      "Open",
      "High",
      "Low",
      "Qty",
    ];
    this.hiddenColumnElements = ["LTV", "Open", "High", "Low"];
    this.rightAlignElement = ["Chg%", "Open", "High", "Low", "Qty"];
    this.activeTab = "all";
    this.sortBy = null;
    this.isSortedAscending = false;
  }

  async fetchData() {
    try {
      await isMoblie({ encyryptedText: this.config.token });
      const data = await fetch(`${NPSTOCKS_LIVE_URL}/widget/stockPrice`);
      const jsonData = await data.json();
      this.data = jsonData;
      this.date = new Date(this.data.lastUpdatedTime);
      this.data = this.data.response;
      this.fetchError = false;
    } catch (e) {
      console.log(e);
      this.fetchError = true;
    }
  }

  async init() {
    // const isOk = await validateToken(this.config.token, this.mainContainer);
    // if (!isOk) {
    //   return;
    // }
    await this.fetchData();
    this.renderContent();
  }

  renderContent() {
    this.mainContainer.className =
      // "m-2 h-[441px] rounded-md bg-white p-3 dark:bg-dark dark:text-white";
      "m-2 h-[441px] rounded-md bg-white py-3 dark:bg-dark dark:text-white";
    this.mainContainer.id = "stock-price";
    this.mainContainer.append(this.dateHeader(), this.nav(), this.header());
    const body = document.createElement("div");
    body.className = "h-[83%] overflow-auto xsm:scrollbar dark:text-darkText px-3";
    body.id = "StockPriceTab-Body";
    this.mainContainer.append(body);
    if (this.fetchError) {
      const errorDiv = document.createElement("div");
      errorDiv.className =
        "flex justify-center h-full items-center dark:text-white/50 text-black/50";
      errorDiv.innerText = "Sorry, an error occured while fetching data.";
      body.append(errorDiv);
    } else {
      this.allPage();
    }
  }

  dateHeader() {
    const container = document.createElement("div");
    container.className =
      "flex justify-end text-[10px] font-[400] leading-3 select-none dark:text-secDarkText px-3";
    container.innerText = `as on ${
      MONTHS[this.date.getMonth()]
    } ${this.date.getDate()}, ${this.date.getFullYear()} ${getHours(
      this.date
    )}`;
    return container;
  }

  nav() {
    const container = document.createElement("nav");
    container.className =
      // "xsm:justify-start flex justify-between gap-x-5 border-b border-lightBorderColor px-3 pb-1 dark:border-darkBorderColor";
      "xsm:justify-start flex justify-between gap-x-5 border-b px-3 pb-1 dark:border-white/20";

    const allBtn = document.createElement("div");
    allBtn.className = this.selectedBtnStyle;
    allBtn.innerText = "All";
    allBtn.id = "StockPriceTab-All-Btn";
    allBtn.onclick = () => {
      this.activeTab = "all";
      this.tabChanger(allBtn.id);
    };

    const bondsBtn = document.createElement("div");
    bondsBtn.className = this.unselectedBtnStyle;
    bondsBtn.innerText = "Bonds";
    bondsBtn.id = "StockPriceTab-Bonds-Btn";
    bondsBtn.onclick = () => {
      this.activeTab = "bonds";
      this.tabChanger(bondsBtn.id);
    };

    const mfBtn = document.createElement("div");
    mfBtn.className = this.unselectedBtnStyle;
    mfBtn.innerText = "Mutual Fund";
    mfBtn.id = "StockPriceTab-Mutual-Fund-Btn";
    mfBtn.onclick = () => {
      this.activeTab = "mfInfo";
      this.tabChanger(mfBtn.id);
    };

    container.append(allBtn, bondsBtn, mfBtn);

    return container;
  }

  tabChanger(btnId) {
    const allBtn = document.getElementById("StockPriceTab-All-Btn");
    const bondsBtn = document.getElementById("StockPriceTab-Bonds-Btn");
    const mfBtn = document.getElementById("StockPriceTab-Mutual-Fund-Btn");
    this.allPage(this.activeTab);
    if (this.activeTab === "all") {
      allBtn.className = this.selectedBtnStyle;
      bondsBtn.className = mfBtn.className = this.unselectedBtnStyle;
    } else if (this.activeTab === "bonds") {
      bondsBtn.className = this.selectedBtnStyle;
      allBtn.className = mfBtn.className = this.unselectedBtnStyle;
    } else {
      mfBtn.className = this.selectedBtnStyle;
      allBtn.className = bondsBtn.className = this.unselectedBtnStyle;
    }
  }

  header() {
    const holderComponent = document.createElement("div");
    holderComponent.className = "px-3 my-1";
    const container = document.createElement("div");
    // container.className = "xsm:grid-cols-9 grid grid-cols-5 border-b p-2";
    container.className =
      "xsm:grid-cols-9 grid grid-cols-[1fr,.5fr,1fr,1fr,1fr;] border-b border-lightBorderColor dark:border-darkBorderColor p-2 dark:text-darkText";
    for (let element of this.headerElementsLables) {
      container.append(this.headerElements(element));
    }
    holderComponent.append(container);
    return holderComponent;
  }

  headerElements(element) {
    const container = document.createElement("div");
    // if (["Ticker", "LTP", "Change"].includes(element)) {
    if (["Ticker"].includes(element)) {
      container.className =
        // "gap-x-1 xsm:gap-1 flex items-center cursor-pointer select-none";
        "gap-x-1 xsm:gap-1 flex items-center select-none";
    } else if (element === "LTV") {
      container.className =
        // "gap-x-1 xsm:gap-1 xsm:flex hidden items-center cursor-pointer select-none";
        "gap-x-1 xsm:gap-1 xsm:flex hidden items-center select-none";
      // } else if (["Chg%", "Qty"].includes(element)) {
    } else if (["LTP", "Change", "Chg%", "Qty"].includes(element)) {
      container.className =
        // "gap-x-1 xsm:gap-1 flex items-center justify-end sm:justify-start cursor-pointer select-none";
        "gap-x-1 xsm:gap-1 flex items-center justify-end sm:justify-start select-none";
    } else if (["Open", "High", "Low"].includes(element)) {
      container.className =
        // "gap-x-1 xsm:gap-1 xsm:flex hidden items-center justify-end sm:justify-start cursor-pointer select-none";
        "gap-x-1 xsm:gap-1 xsm:flex hidden items-center justify-end sm:justify-start select-none";
    }

    const elementContainer = document.createElement("div");
    elementContainer.className = "text-[12px] font-[600] leading-4";
    elementContainer.innerText = element;

    const arrow = document.createElement("div");
    arrow.innerHTML = `<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_9_179)">
      <path d="M3.01404 1.42188V8.57813" stroke="#767884" stroke-width="0.65625" />
      <path d="M5.02189 3.32969L3.00939 1.42188L1.17657 3.32969" stroke="#767884" stroke-width="0.65625" />
      <path d="M6.82349 8.57813V1.42188" stroke="#767884" stroke-width="0.65625" />
      <path d="M8.83127 6.67032L6.81877 8.57813L4.98596 6.67032" stroke="#767884" stroke-width="0.65625" />
    </g>
    <defs>
      <clipPath id="clip0_9_179">
        <rect width="10" height="10" fill="white" />
      </clipPath>
    </defs>
  </svg>`;

    container.append(elementContainer, arrow);
    container.onclick = () => {
      if (this.sortBy !== element) {
        this.isSortedAscending = false;
      }
      this.sortBy = element;
      if (!this.isSortedAscending) {
        this.data[this.activeTab] = this.data[this.activeTab].sort((a, b) => {
          this.isSortedAscending = true;
          if (element === "Ticker") {
            return a.ticker.toLocaleLowerCase().localeCompare(b.ticker);
          }
          if (element === "LTP") {
            return a.ltp - b.ltp;
          }
          if (element === "LTV") {
            return a.ltv - b.ltv;
          }
          if (element === "Change") {
            return a.pointChange - b.pointChange;
          }
          if (element === "Chg%") {
            return a.percentageChange - b.percentageChange;
          }
          if (element === "Open") {
            return a.open - b.open;
          }
          if (element === "High") {
            return a.high - b.high;
          }
          if (element === "Low") {
            return a.low - b.low;
          }
          if (element === "Qty") {
            return a.qty - b.qty;
          }
          return a - b;
        });
      } else {
        this.data[this.activeTab] = this.data[this.activeTab].sort((a, b) => {
          this.isSortedAscending = false;
          if (element === "Ticker") {
            return b.ticker.toLocaleLowerCase().localeCompare(a.ticker);
          }
          if (element === "LTP") {
            return b.ltp - a.ltp;
          }
          if (element === "LTV") {
            return b.ltv - a.ltv;
          }
          if (element === "Change") {
            return b.pointChange - a.pointChange;
          }
          if (element === "Chg%") {
            return b.percentageChange - a.percentageChange;
          }
          if (element === "Open") {
            return b.open - a.open;
          }
          if (element === "High") {
            return b.high - a.high;
          }
          if (element === "Low") {
            return b.low - a.low;
          }
          if (element === "Qty") {
            return b.qty - a.qty;
          }
          return b - a;
        });
      }
      this.allPage(this.activeTab);
    };
    return container;
  }

  elementContainer(itemValue, element) {
    let isNegative = false;
    if (element === "Change" || element === "Chg%") {
      isNegative = isNegativeNumber(itemValue);
    }
    const valueContainer = document.createElement("div");
    // if (["Ticker", "LTP", "Change"].includes(element)) {
    if (["Ticker"].includes(element)) {
      valueContainer.className = "flex";
    } else if (element === "LTV") {
      valueContainer.className = "xsm:flex hidden";
      // } else if (["Chg%", "Qty"].includes(element)) {
    } else if (["Change", "LTP", "Chg%", "Qty"].includes(element)) {
      valueContainer.className = "flex justify-end sm:justify-start";
    } else if (["Open", "High", "Low"].includes(element)) {
      valueContainer.className = "xsm:flex hidden justify-end sm:justify-start";
    }

    const value = document.createElement("div");
    if (element === "Change" || element === "Chg%") {
      if (isNegative) {
        value.className =
          "xsm:text-[12px] text-[12px] font-[500] leading-4 text-red";
      } else {
        value.className =
          "xsm:text-[12px] text-[12px] font-[500] leading-4 dark:text-darkGreen text-green";
      }
    }
    // else if (element === "Ticker") {
    //   value.className = "xsm:text-[14px] text-[12px] font-[500] leading-4";
    // }
    else {
      value.className = "xsm:text-[12px] text-[12px] font-[500] leading-4";
    }

    if (["LTP", "Change", "Chg%", "Open", "High", "Low"].includes(element)) {
      value.innerText = commaSeperatedFloats(itemValue);
      if (element === "Change" || element === "Chg%") {
        if (!isNegative) {
          value.innerText = "+" + commaSeperatedFloats(itemValue);
        }
      }
    } else if (element === "Qty") {
      value.innerText = itemValue.toLocaleString("en-US");
    } else {
      value.innerText = itemValue;
    }

    valueContainer.append(value);

    return valueContainer;
  }

  allPage() {
    const body = document.getElementById("StockPriceTab-Body");
    body.innerHTML = "";
    if (this.fetchError) {
      const errorDiv = document.createElement("div");
      errorDiv.className =
        "flex justify-center h-full items-center dark:text-white/50 text-black/50";
      errorDiv.innerText = "Sorry, an error occured while fetching data.";
      body.append(errorDiv);
    } else {
      for (let data of this.data[this.activeTab]) {
        const container = document.createElement("div");
        container.className =
          // "xsm:grid-cols-9 grid grid-cols-5 border-b p-2 hover:bg-[#F2F3F5]";
          "xsm:grid-cols-9 grid grid-cols-[1fr,.5fr,1fr,1fr,1fr;] border-b border-lightBorderColor dark:border-darkBorderColor p-2 hover:bg-lightHoverColor dark:hover:bg-slate-800 dark:border-darkBorderColor border-lightBorderColor";
        for (let element of this.headerElementsLables) {
          let extractionData = "";
          if (
            ["Ticker", "LTP", "LTV", "Open", "High", "Low", "Qty"].includes(
              element
            )
          ) {
            extractionData = element.toLocaleLowerCase();
          } else if (element === "Change") {
            extractionData = "pointChange";
          } else if (element === "Chg%") {
            extractionData = "percentageChange";
          }
          container.append(
            this.elementContainer(data[extractionData], element)
          );
        }
        body.append(container);
      }
    }
  }
}
