import {
  commaSeperatedFloats,
  isMoblie,
  isNegativeNumber,
  NPSTOCKS_LIVE_URL,
} from "../Utils";

export default class ScrollableTicker {
  constructor({ token, id }) {
    this.config = { token, id };
    this.mainContainer = document.querySelector(id);
    this.mainContainer.innerHTML = "";
    this.style = document.createElement("style");
    this.style.innerHTML = `@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
                    ${id}{font-family: 'Inter', sans-serif;}
                    ${id}::-webkit-scrollbar {
                        display: none;
                    }
                    `;

    this.mainContainer.appendChild(this.style);
    this.data = {};
    this.fetchError = false;
  }

  async fetchData() {
    try {
      await isMoblie({ encyryptedText: this.config.token });
      const data = await fetch(`${NPSTOCKS_LIVE_URL}/widget/stock-live`);
      this.data = await data.json();
      this.data = this.data.response;
      this.fetchError = false;
    } catch (e) {
      console.log("Error Fetching Data", e);
      this.fetchError = true;
    }
  }

  async init() {
    await this.fetchData();
    this.renderContent();
  }

  renderContent() {
    this.mainContainer.className =
      "m-2 dark:bg-dark flex h-[47px] items-center dark:text-darkText overflow-x-auto xsm:no-scrollbar overflow-y-hidden rounded-md bg-white py-2";
    if (this.fetchError) {
      const container = document.createElement("div");
      container.className = "flex items-center w-full justify-center";

      const errorMsg = document.createElement("div");
      errorMsg.className = "dark:text-white/50 text-black/50";
      errorMsg.innerText = "Sorry, an error occured while fetching data.";
      container.appendChild(errorMsg);
      this.mainContainer.append(container);
    } else {
      this.mainContainer.onwheel = (evt) => {
        evt.preventDefault();
        this.mainContainer.scrollLeft += evt.deltaY;
      };
      this.bodyContainer();
    }
  }

  bodyContainer() {
    const container = document.createElement("div");
    container.className =
      "flex items-center animate-infinite-scroll hover:pause";
    this.mainContainer.append(container);
    for (let data of this.data) {
      container.append(
        this.element({
          symbol: data.symbol,
          ltp: data.ltp,
          percentageChange: data.percentageChange,
        })
      );
    }
  }

  element({ symbol, ltp, percentageChange }) {
    const isNegative = isNegativeNumber(percentageChange);
    const container = document.createElement("div");
    container.className = "border-r py-1 px-5";

    const innerContainer = document.createElement("div");
    innerContainer.className = "flex w-[240px] items-center gap-x-4 justify-between";

    const symbolContainer = document.createElement("div");
    symbolContainer.className = "text-[16px] font-[700] leading-5";
    symbolContainer.innerText = symbol;

    const ltpContainer = document.createElement("div");
    ltpContainer.className = "text-[16px] font-[500] leading-5";
    ltpContainer.innerText = commaSeperatedFloats(ltp);

    const logoPercentageContainer = document.createElement("div");
    logoPercentageContainer.className = "flex items-center gap-x-2";

    const percentageContainer = document.createElement("div");
    percentageContainer.className = "text-[14px] font-[500] leading-5";
    percentageContainer.classList.add(
      isNegative ? "text-[#E15562]" : "text-[#53A895]"
    );
    percentageContainer.innerText = isNegative
      ? commaSeperatedFloats(percentageChange) + "%"
      : "+" + commaSeperatedFloats(percentageChange) + "%";

    const logoContainer = document.createElement("div");
    logoContainer.innerHTML = isNegative
      ? `<svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.5 12L0.00480938 0.75L12.9952 0.75L6.5 12Z" fill="#E15562"/>
    </svg>
    `
      : `<svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.5 0L12.9952 11.25H0.00480938L6.5 0Z" fill="#53A895"/>
    </svg>
    `;

    logoPercentageContainer.append(percentageContainer, logoContainer);

    innerContainer.append(
      symbolContainer,
      ltpContainer,
      logoPercentageContainer
    );

    container.appendChild(innerContainer);
    return container;
  }
}
