import { validateToken } from "../Auth";
import { commaSeperatedFloats, isMoblie, NPSTOCKS_LIVE_URL } from "../Utils";

export default class IPO {
  constructor({ token, id }) {
    this.config = { token, id };
    this.mainContainer = document.querySelector(id);
    this.mainContainer.innerHTML = "";
    this.style = document.createElement("style");
    this.style.innerHTML = `@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
                    ${id}{font-family: 'Inter', sans-serif;}`;
    this.mainContainer.appendChild(this.style);
    this.data = {};
    this.date = new Date();
    this.fetchError = false;
    this.selectedBtnStyle =
      // "cursor-pointer select-none rounded-[5px] bg-[#624BFE] px-[20px] py-[4px] text-[12px] font-[400] leading-[15px] text-white";
      "select-none rounded-[5px] bg-[#624BFE] px-[20px] py-[4px] text-[12px] font-[400] leading-[15px] text-white";
    this.unselectedBtnStyle =
      // "cursor-pointer select-none rounded-[5px] px-[20px] py-[4px] text-[12px] font-[400] leading-[15px] hover:bg-[#624BFE]/50 hover:text-white";
      "select-none rounded-[5px] px-[20px] py-[4px] text-[12px] font-[400] leading-[15px] hover:bg-[#624BFE]/50 hover:text-white";
    this.activeBtn = "Filed";
  }

  async init() {
    // const isOk = await validateToken(this.config.token, this.mainContainer);
    // if (!isOk) {
    //   return;
    // }
    await this.fetchData();
    this.showContent();
  }

  async fetchData() {
    try {
      await isMoblie({ encyryptedText: this.config.token });
      const data = await fetch(`${NPSTOCKS_LIVE_URL}/widget/ipo-info`);
      this.apiData = await data.json();
      this.apiData = this.apiData.response;

      this.apiData.filed = this.apiData.filed.map((value) => {
        if (!value.openDate) {
          return {
            ticker: value.Company,
            sector: value.Sector,
            type: value.Type,
            rate: value.Rate,
            offeredTo: value.Offeredto,
            shares: value.Shares,
            issueManager: value.Issuemanger,
            applicationDate: value.Applicationdate,
            approvalDate: value.Approvaldate,
            openDate: value.Opendate,
            closeDate: value.Closedate,
          };
        }
      });

      let filedData = [];
      for (let data of this.apiData.filed) {
        if (data !== undefined) {
          filedData.push(data);
        }
      }
      this.apiData.filed = filedData;
      this.apiData.filed.sort((a, b) => {
        return new Date(b.applicationDate) - new Date(a.applicationDate);
      });
      // let upComming = this.apiData.upComming.map((value) => {
      //   const todayDate = new Date();
      //   const openDate = new Date(value.Opendate);
      //   if (todayDate < openDate) {
      //     return {
      //       ticker: value.Company,
      //       sector: value.Sector,
      //       type: value.Type,
      //       rate: value.Rate,
      //       offeredTo: value.Offeredto,
      //       shares: value.Shares,
      //       issueManager: value.Issuemanger,
      //       applicationDate: value.Applicationdate,
      //       approvalDate: value.Approvaldate,
      //       openDate: value.Opendate,
      //       closeDate: value.Closedate,
      //     };
      //   }
      // });
      let upComming = this.apiData.upComming.map((value) => {
        return {
          ticker: value.Company,
          sector: value.Sector,
          type: value.Type,
          rate: value.Rate,
          offeredTo: value.Offeredto,
          shares: value.Shares,
          issueManager: value.Issuemanger,
          applicationDate: value.Applicationdate,
          approvalDate: value.Approvaldate,
          openDate: value.Opendate,
          closeDate: value.Closedate,
        };
      });
      let upCommingData = [];
      for (let data of upComming) {
        if (data !== undefined) {
          upCommingData.push(data);
        }
      }
      upComming = upCommingData;
      this.apiData.upComing = upComming;
      this.apiData.upComing.sort((a, b) => {
        return new Date(b.openDate) - new Date(a.openDate);
      });

      // let onGoing = this.apiData.onGoing.map((value) => {
      //   if (value.Opendate && value.Closedate) {
      //     const todayDate = new Date();
      //     const openDate = new Date(value.Opendate);
      //     const closeDate = new Date(value.Closedate);
      //     if (todayDate >= openDate && todayDate <= closeDate) {
      //       return {
      //         ticker: value.Company,
      //         sector: value.Sector,
      //         type: value.Type,
      //         rate: value.Rate,
      //         offeredTo: value.Offeredto,
      //         shares: value.Shares,
      //         issueManager: value.Issuemanger,
      //         applicationDate: value.Applicationdate,
      //         approvalDate: value.Approvaldate,
      //         openDate: value.Opendate,
      //         closeDate: value.Closedate,
      //       };
      //     }
      //   }
      // });
      let onGoing = this.apiData.onGoing.map((value) => {
        return {
          ticker: value.Company,
          sector: value.Sector,
          type: value.Type,
          rate: value.Rate,
          offeredTo: value.Offeredto,
          shares: value.Shares,
          issueManager: value.Issuemanger,
          applicationDate: value.Applicationdate,
          approvalDate: value.Approvaldate,
          openDate: value.Opendate,
          closeDate: value.Closedate,
        };
      });
      let onGoingData = [];
      for (let data of onGoing) {
        if (data !== undefined) {
          onGoingData.push(data);
        }
      }
      onGoing = onGoingData;
      this.apiData.onGoing = onGoing;
      this.apiData.onGoing.sort((a, b) => {
        return new Date(b.openDate) - new Date(a.openDate);
      });
      this.fetchError = false;
    } catch (e) {
      console.log(e);
      this.fetchError = true;
    }
  }

  showContent() {
    this.mainContainer.className =
      "m-2 h-[713px] overflow-hidden rounded-md bg-white dark:bg-dark dark:text-darkText";

    this.mainContainer.append(this.header());

    const body = document.createElement("div");
    body.className = "h-[94%] overflow-auto xsm:scrollbar";
    body.id = "IPO-widget-body";
    this.mainContainer.append(body);
    if (this.fetchError) {
      body.append(this.errorBody({ calledFrom: "" }));
    } else {
      this.filedPage();
    }
  }

  errorBody({ calledFrom = "" }) {
    const dataContainer = document.createElement("div");
    dataContainer.id = "IPO-widget-Error-body";
    dataContainer.classList.add(
      "flex",
      "justify-center",
      "items-center",
      "h-[94%]",
      "text-black/30",
      "dark:text-white/30"
    );
    dataContainer.innerText =
      calledFrom === "" ? "Unable to fetch data." : "No Data Found";
    return dataContainer;
  }

  header() {
    const container = document.createElement("div");
    container.className = "mt-1 flex flex-col gap-y-2";

    const filedBtn = document.createElement("div");
    filedBtn.id = "IPO-Filed-Btn";
    filedBtn.className = this.selectedBtnStyle;
    filedBtn.innerText = "Filed";
    filedBtn.onclick = () => {
      this.tabChanger(filedBtn.id);
    };

    const upcomingBtn = document.createElement("div");
    upcomingBtn.id = "IPO-Upcoming-Btn";
    upcomingBtn.className = this.unselectedBtnStyle;
    upcomingBtn.innerText = "Upcoming";
    upcomingBtn.onclick = () => {
      this.tabChanger(upcomingBtn.id);
    };

    const ongoingBtn = document.createElement("div");
    ongoingBtn.id = "IPO-Ongoing-Btn";
    ongoingBtn.className = this.unselectedBtnStyle;
    ongoingBtn.innerText = "Ongoing";
    ongoingBtn.onclick = () => {
      this.tabChanger(ongoingBtn.id);
    };

    const btnContainer = document.createElement("div");
    btnContainer.className =
      "xsm:justify-start mt-1 flex justify-between gap-x-5 px-2";
    btnContainer.append(filedBtn, upcomingBtn, ongoingBtn);

    const border = document.createElement("div");
    border.className = "border-b dark:border-white/50";

    container.append(btnContainer, border);

    return container;
  }

  filedPage() {
    const body = document.getElementById("IPO-widget-body");
    body.innerHTML = "";

    const container = document.createElement("div");
    container.className = "my-2 grid grid-cols-[.7fr,.3fr;] gap-x-2 px-4";
    const emptyDiv = document.createElement("div");

    const filingLabel = document.createElement("div");
    filingLabel.innerText = "Filing Date";
    filingLabel.className = "text-[10px] leading-3 text-secDarkText";
    container.append(emptyDiv, filingLabel);
    if (this.apiData.filed.length === 0) {
      body.append(this.errorBody({ calledFrom: "ongoing" }));
    } else {
      body.append(container);
    }
    for (let element of this.apiData.filed) {
      body.append(
        this.titleContainer(element.ticker, element.applicationDate, element),
        this.detailsTab({
          element: element,
          type: element.type,
          offerPrice: element.rate,
          offeredTo: element.offeredTo,
          sharesOffered: parseInt(element.shares).toLocaleString("en-US"),
          issueManager: element.issueManager,
          applicationDate: element.applicationDate,
          price: element.rate,
        })
      );
    }
  }

  upcomingPage() {
    const body = document.getElementById("IPO-widget-body");
    body.innerHTML = "";

    const container = document.createElement("div");
    container.className = "my-2 grid grid-cols-[.7fr,.3fr;] gap-x-2 px-4";
    const emptyDiv = document.createElement("div");

    const filingLabel = document.createElement("div");
    filingLabel.innerText = "Open Date";
    filingLabel.className = "text-[10px] leading-3 text-[#767676]";
    container.append(emptyDiv, filingLabel);
    if (this.apiData.upComing.length === 0) {
      body.append(this.errorBody({ calledFrom: "ongoing" }));
    } else {
      body.append(container);
    }
    for (let element of this.apiData.upComing) {
      body.append(
        this.titleContainer(element.ticker, element.openDate, element),
        this.detailsTab({
          element: element,
          type: element.type,
          offerPrice: element.rate,
          offeredTo: element.offeredTo,
          sharesOffered: parseInt(element.shares).toLocaleString("en-US"),
          issueManager: element.issueManager,
          applicationDate: element.applicationDate,
          price: element.rate,
          calledFrom: "upcoming",
          openDate: element.openDate,
          closeDate: element.closeDate,
          approvalDate: element.approvalDate,
        })
      );
    }
  }

  ongoingPage() {
    const body = document.getElementById("IPO-widget-body");
    body.innerHTML = "";

    const container = document.createElement("div");
    container.className = "my-2 grid grid-cols-[.7fr,.3fr;] gap-x-2 px-4";
    const emptyDiv = document.createElement("div");

    const filingLabel = document.createElement("div");
    filingLabel.innerText = "Open Date";
    filingLabel.className = "text-[10px] leading-3 text-[#767676]";
    container.append(emptyDiv, filingLabel);
    if (this.apiData.onGoing.length === 0) {
      body.append(this.errorBody({ calledFrom: "ongoing" }));
    } else {
      body.append(container);
    }
    for (let element of this.apiData.onGoing) {
      body.append(
        this.titleContainer(element.ticker, element.openDate, element),
        this.detailsTab({
          element: element,
          type: element.type,
          offerPrice: element.rate,
          offeredTo: element.offeredTo,
          sharesOffered: parseInt(element.shares).toLocaleString("en-US"),
          issueManager: element.issueManager,
          applicationDate: element.applicationDate,
          price: element.rate,
          calledFrom: "ongoing",
          openDate: element.openDate,
          closeDate: element.closeDate,
          approvalDate: element.approvalDate,
        })
      );
    }
  }

  tabChanger(id) {
    const filedBtn = document.getElementById("IPO-Filed-Btn");
    const upcomingBtn = document.getElementById("IPO-Upcoming-Btn");
    const ongoingBtn = document.getElementById("IPO-Ongoing-Btn");

    if (this.fetchError) {
      for (let btn of [filedBtn, upcomingBtn, ongoingBtn]) {
        if (id === btn.id) {
          btn.className = this.selectedBtnStyle;
        } else {
          btn.className = this.unselectedBtnStyle;
        }
      }
    } else {
      if (id === "IPO-Filed-Btn") {
        filedBtn.className = this.selectedBtnStyle;
        this.activeBtn = "Filed";
        upcomingBtn.className = ongoingBtn.className = this.unselectedBtnStyle;
        this.filedPage();
      } else if (id === "IPO-Upcoming-Btn") {
        upcomingBtn.className = this.selectedBtnStyle;
        this.activeBtn = "Upcoming";
        filedBtn.className = ongoingBtn.className = this.unselectedBtnStyle;
        this.upcomingPage();
      } else if (id === "IPO-Ongoing-Btn") {
        ongoingBtn.className = this.selectedBtnStyle;
        this.activeBtn = "Ongoing";
        upcomingBtn.className = filedBtn.className = this.unselectedBtnStyle;
        this.ongoingPage();
      }
    }
  }

  titleContainer(name, date, element) {
    const container = document.createElement("div");
    container.className =
      // "grid cursor-pointer grid-cols-[.7fr,.3fr;] gap-x-2 border-b border-lightBorderColor dark:border-darkBorderColor p-3 px-4 hover:bg-lightHoverColor dark:hover:bg-slate-800";
      "grid grid-cols-[.7fr,.3fr;] gap-x-2 border-b border-lightBorderColor dark:border-darkBorderColor p-3 px-4 hover:bg-lightHoverColor dark:hover:bg-slate-800";

    const nameContainer = document.createElement("div");
    nameContainer.className =
      "text-[14px] font-[400] leading-4 text-clickAbleLinkColor dark:text-cyan-600";
    nameContainer.innerText = name;

    const dateContainer = document.createElement("div");
    dateContainer.className = "text-[12px] font-[400] leading-4";
    dateContainer.innerText = date;

    container.append(nameContainer, dateContainer);
    container.onclick = () => {
      const subElement = document.getElementById(
        element.ticker + element.applicationDate + element.issueManager
      );
      if (subElement.classList.contains("hidden")) {
        subElement.classList.remove("hidden");
        container.classList.add("bg-lightHoverColor", "dark:bg-slate-800");
      } else if (!subElement.classList.contains("hidden")) {
        subElement.classList.add("hidden");
        container.classList.remove("bg-lightHoverColor", "dark:bg-slate-800");
      }
    };

    return container;
  }

  detailsTab({
    element,
    type,
    offerPrice,
    offeredTo,
    sharesOffered,
    issueManager,
    applicationDate,
    price,
    calledFrom = "",
    approvalDate = "",
    openDate = "",
    closeDate = "",
  }) {
    const container = document.createElement("div");
    container.className =
      "hidden border-b border-lightBorderColor dark:border-darkBorderColor";
    container.id =
      element.ticker + element.applicationDate + element.issueManager;

    const progressContainer = document.createElement("div");
    progressContainer.className = "my-7 flex flex-col px-4";

    progressContainer.append(
      this.progressTracker({
        applicationDate,
        progressStatus: "Active",
        label: "Date of Application",
      }),
      this.progressTrackerLine({
        isActive: calledFrom === "upcoming" || calledFrom === "ongoing",
        containerStyle: "my-1",
      }),
      this.progressTracker({
        applicationDate: approvalDate,
        progressStatus:
          calledFrom === "upcoming" || calledFrom === "ongoing"
            ? "Active"
            : "Inactive",
        label: "Date of Approval",
        price: "Price: Rs: " + commaSeperatedFloats(parseInt(price)),
      }),
      this.progressTrackerLine({
        isActive: calledFrom === "ongoing",
        containerStyle: "mt-[-6px] mb-1",
      }),
      this.progressTracker({
        applicationDate: openDate,
        progressStatus: calledFrom === "ongoing" ? "Active" : "Inactive",
        label: "IPO Open Date",
        price:
          calledFrom === ""
            ? ""
            : "Price: Rs: " + commaSeperatedFloats(parseInt(price)),
      }),
      this.progressTrackerLine({
        isActive: calledFrom === "ongoing",
        containerStyle: calledFrom === "" ? "my-1" : "mt-[-6px] mb-1",
      }),
      this.progressTracker({
        applicationDate: closeDate,
        progressStatus: calledFrom === "ongoing" ? "Inactive" : "Inactive",
        label: "IPO Close Date",
      })
    );

    container.append(
      this.subDetailsContainer({
        type,
        offerPrice,
        offeredTo,
        sharesOffered,
        issueManager,
      }),
      progressContainer
    );
    return container;
  }

  subTitleContainer(name, value) {
    const container = document.createElement("div");
    container.className = "mt-3 grid grid-cols-[1fr_2fr]";

    const nameContainer = document.createElement("div");
    nameContainer.className = "text-[10px] font-[400] leading-4 text-[#767676]";
    nameContainer.innerText = name;

    const valueContainer = document.createElement("div");
    valueContainer.className = "text-[12px] font-[500] leading-4";
    valueContainer.innerText = value;

    container.append(nameContainer, valueContainer);

    return container;
  }

  subDetailsContainer({
    type,
    offerPrice,
    offeredTo,
    sharesOffered,
    issueManager,
  }) {
    const container = document.createElement("div");
    container.className = "mt-4 px-4";
    const id = [
      "TYPE",
      "OFFER PRICE",
      "OFFERED TO",
      "SHARES OFFERED",
      "ISSUE MANAGER",
    ];
    const values = [type, offerPrice, offeredTo, sharesOffered, issueManager];
    for (let i = 0; i < id.length; i++) {
      container.append(this.subTitleContainer(id[i], values[i]));
    }
    return container;
  }

  progressTrackerLine({ isActive = false, containerStyle = "" }) {
    const container = document.createElement("div");
    container.className = `grid grid-cols-[.6fr,.3fr,1fr] ${containerStyle}`;

    const dateContainer = document.createElement("div");
    dateContainer.className = "flex justify-end";

    const dateValue = document.createElement("div");
    dateValue.className = "text-[12px] font-[600] leading-4 text-[#2D3648]";
    // dateValue.innerText = applicationDate;

    dateContainer.append(dateValue);

    const progressSvg = document.createElement("div");
    progressSvg.className = "flex justify-center";
    progressSvg.innerHTML = isActive
      ? `<svg width="2" height="41" viewBox="0 0 2 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="1" x2="1" y2="40.0125" stroke="#2962FF" stroke-width="2"/>
      </svg>
      `
      : `<svg width="2" height="41" viewBox="0 0 2 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="1" x2="1" y2="40.0125" stroke="#D9D9D9" stroke-width="2"/>
      </svg>
              
        `;

    const labelContainer = document.createElement("div");
    labelContainer.className =
      "text-[12px] font-[600] leading-4 text-[#2D3648]";
    // labelContainer.innerText = label;

    const priceContainer = document.createElement("div");
    priceContainer.className =
      "text-[10px] font-[400] leading-4 text-[#767676]";
    // priceContainer.innerText = price;

    const rightLabels = document.createElement("div");
    rightLabels.append(labelContainer, priceContainer);

    container.append(dateContainer, progressSvg, rightLabels);
    return container;
  }
  progressTracker({
    applicationDate,
    progressStatus = "Inactive",
    label,
    price = "",
  }) {
    const container = document.createElement("div");
    container.className = "grid grid-cols-[.6fr,.3fr,1fr]";

    const dateContainer = document.createElement("div");
    dateContainer.className = "flex justify-end";

    const dateValue = document.createElement("div");
    dateValue.className =
      "text-[12px] font-[600] leading-4 text-[#2D3648] dark:text-darkText";
    dateValue.innerText = applicationDate;

    dateContainer.append(dateValue);

    const progressSvg = document.createElement("div");
    progressSvg.className = "flex justify-center";
    progressSvg.innerHTML =
      progressStatus === "Active"
        ? `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8" cy="8" r="8" fill="#2962FF" />
  </svg>`
        : `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="8" cy="8" r="8" fill="#D9D9D9"/>
        </svg>
        `;

    const labelContainer = document.createElement("div");
    labelContainer.className =
      "text-[12px] font-[600] leading-4 text-[#2D3648] dark:text-darkText";
    labelContainer.innerText = label;

    const priceContainer = document.createElement("div");
    priceContainer.className =
      "text-[10px] font-[400] leading-3 text-[#767676]";
    priceContainer.innerText = price;

    const rightLabels = document.createElement("div");
    rightLabels.append(labelContainer, priceContainer);

    container.append(dateContainer, progressSvg, rightLabels);
    return container;
  }
}
