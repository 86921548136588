import {validateToken} from "../Auth";
import Indices from '../Indices';
import {NPSTOCKS_URL} from "../Utils";
import {createSpinner} from "../Utils/UI";
import * as Apexcharts from "apexcharts";

export default class WhatIf {
    constructor({token, id}) {
        this.config = {token, id};
        this.mainContainer = document.querySelector(id);
    }

    async init() {
        const isOk = await validateToken(this.config.token, this.mainContainer);
        if (!isOk) {
            return;
        }
        await this.fetchData();
        this.showContent();
    }

    async fetchData() {
        try {
            this.mainContainer.appendChild(createSpinner());
            const data = await fetch(`${NPSTOCKS_URL}/announcements`);
            this.apiData = await data.json();
        } catch (e) {
            console.log(e);
        }
    }

    async fetchDatafromInput(formData) {
        console.log(formData);
        try {
            // use this for the get through input api
            // const data = await fetch(`${NPSTOCKS_URL}/`, {
            //     method: 'get',
            //     body: formData
            // });

            const data = await fetch(`${NPSTOCKS_URL}/timeseries`);
            // remove this after the api
            const parsedData = await data.json();
            console.log("parsedData",parsedData);
            // to be changed after....
            this.chartData = parsedData.response;

        } catch (e) {
            console.log(e);
        }
    }

    createTab() {
        const divContainer = document.createElement('div');
        divContainer.className = 'smtm-what-if-tab';
        const nav = document.createElement('nav');
        nav.className = 'flex items-center font-medium ml-3 mb-2 border-gray-100 dark:border-gray-500';
        const icon = document.createElement('div');
        icon.className = 'border border-black rounded-lg bg-slate-100 p-2 dark:bg-dark dark:text-slate-400';
        icon.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             className="bi bi-graph-up-arrow " viewBox="0 0 16 16">
            <path fill-rule="evenodd"
                  d="M0 0h1v15h15v1H0V0Zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5Z"/>
        </svg>`;
        const title = document.createElement('h3');
        title.className = 'text-xl font-bold ml-9';
        title.innerHTML = 'What if you had started investing years ago?';
        nav.appendChild(icon);
        nav.appendChild(title);
        const info = document.createElement('div');
        info.className = 'flex text-xs mb-4 pl-4 font-normal bg-slate-100 border-gray-100 dark:border-gray-500 dark:bg-slate-500';
        info.innerHTML = 'Choose a stock to see what would the value would be today.';
        divContainer.appendChild(nav);
        divContainer.appendChild(info);
        return divContainer;
    }

    createForm(datas) {
        const formContainer = document.createElement('div');
        const form = document.createElement('form');
        form.className = 'm-4';

        const stocklabel = document.createElement('label');
        stocklabel.innerHTML = "SELECT A STOCK";
        stocklabel.setAttribute('for', 'stocks');
        stocklabel.className = 'label';

        const investmentlabel = document.createElement('label');
        investmentlabel.innerHTML = "SELECT INVESTMENT AMOUNT (Rs.)";
        investmentlabel.setAttribute('for', 'investment');
        investmentlabel.className = 'label';

        const purchaseDateLabel = document.createElement('label');
        purchaseDateLabel.innerHTML = "SELECT PURCHASE DATE";
        purchaseDateLabel.setAttribute('for', 'purchaseDate');
        purchaseDateLabel.className = 'label';

        const stock = document.createElement('select');
        stock.setAttribute('name', 'stocks');

        const selectStock = document.createElement('option');
        selectStock.setAttribute('selected', '');
        selectStock.setAttribute('disabled', '');
        selectStock.setAttribute('hidden', '');
        selectStock.innerHTML = 'Select  a stock from dropdown';
        stock.innerHTML = this.createOptions(datas);
        stock.appendChild(selectStock);
        stock.className = 'select';

        const radioContainer = document.createElement('div');
        radioContainer.className = 'radio mt-4 mb-4';
        const radioDiv = document.createElement('div');
        radioDiv.setAttribute('name', 'investment');
        radioDiv.className = 'investment';
        radioDiv.innerHTML = this.createRadio('Amount');
        radioContainer.appendChild(investmentlabel);
        radioContainer.appendChild(radioDiv);

        const purchaseDate = document.createElement('select');
        purchaseDate.setAttribute('name', 'purchaseDate');
        purchaseDate.innerHTML = this.createOptions(datas);
        purchaseDate.className = 'select1';

        const submit = document.createElement('input');
        submit.setAttribute('type', 'submit');
        submit.setAttribute('value', '+ Calculate');
        console.log("this.result", this.resultContainer);
        form.addEventListener('submit', async (e) => {
            e.preventDefault()
            const formData = new FormData(form);
            await this.fetchDatafromInput(formData);
            this.mainContainer.replaceChild(this.resultPage(), this.container);
        });

        submit.className = 'block submit bg-slate-200 dark:bg-slate-500 dark:border-gray-500';

        form.appendChild(stocklabel);
        form.appendChild(stock);
        form.appendChild(radioContainer);
        form.appendChild(purchaseDateLabel);
        form.appendChild(purchaseDate);
        form.append(submit);
        formContainer.appendChild(form);

        return formContainer;
    }

    createOptions(values) {
        return values.map((data) => {
            return `<option value="${data.ticker}">${data.ticker}</option>`;
        }).join('');
    }

    createRadio(Name) {
        const values = ['1000', '10000', '1 lakh', '10 lakh', '1 crore', '10 crore'];
        return values.map((data) => {
            return `<span class="mb-2 flex justify-between" style="width: 90px">
                        <input type="radio" value="${data}" name="${Name}" id="${data}" >
                        <label for="${data}" style="font-weight: 600; font-size: 16px;">${data}</label>
                    </span>`;
        }).join('');
    }

    resultPage() {
        const resultContainer = document.createElement('div');
        resultContainer.className = 'resultContainer';
        resultContainer.appendChild(this.createResultTab({
            amount: '1 lakh',
            stock: 'NABIL',
            timestrain: '5 years ago'
        }));
        resultContainer.appendChild(this.createResultTable({
            netIncome: 'Rs.589,909.98',
            increasePercent: '920%',
            annualized: '14.47%'
        }));
        this.resultContainer = resultContainer;
        return resultContainer;
    }

    createResultTab({amount, stock, timestrain}) {
        const divContainer = document.createElement('div');
        divContainer.className = 'smtm-what-if-tab';
        const nav = document.createElement('nav');
        nav.style.display = 'flex';
        nav.style.justifyContent = 'end';
        const button = document.createElement('button');
        button.className = 'dark:stroke-slate-400';
        button.innerHTML = `<svg width="14" height="27" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.3398 15H9.88281L6.5625 9.59961L3.24219 15H0L4.73633 7.63672L0.302734 0.722656H3.64258L6.71875 5.85938L9.73633 0.722656H12.998L8.51562 7.80273L13.3398 15Z" fill="black"/>
        </svg>`;
        button.addEventListener('click', () => {
            this.mainContainer.replaceChild(this.container, this.resultContainer);
        });

        const info = document.createElement('div');
        info.className = 'flex text-xs mb-4 pl-4 h-5 text-base font-medium bg-slate-100 border-gray-100 dark:border-gray-500 dark:bg-slate-500';
        info.innerHTML = `If you had invested Rs. <span class="resultspan">'${amount}'</span> in <span class="resultspan"> '${stock}'</span> stock <span class="resultspan"> '${timestrain}'</span>`

        nav.appendChild(button);
        divContainer.appendChild(nav);
        divContainer.appendChild(info);
        return divContainer;
    }

    createResultTable({netIncome, increasePercent, annualized}) {
        const resultTable = document.createElement('div');
        resultTable.className = 'flex';
        const resultLeft = document.createElement('div');
        resultLeft.className = 'mt-2 ml-4';
        
        const p = document.createElement('p');
        p.innerHTML = 'today the investment would be worth';
        p.className = 'text-base font-normal mb-2';
        const h3 = document.createElement('h3');
        h3.className = 'font-bold text-2xl font-sans text-sky-700 mb-4';
        h3.innerHTML = netIncome;

        const totalIncrease = document.createElement('div');
        totalIncrease.className = 'totalIncrease';
        const p1 = document.createElement('p');
        p1.innerHTML = 'Total Increase';
        const h31 = document.createElement('h3');
        h31.className ='font-bold text-xl';
        h31.innerHTML += increasePercent;
        totalIncrease.appendChild(p1);
        totalIncrease.appendChild(h31);

        const AnnualReturn = document.createElement('div');
        const p2 = document.createElement('p');
        p2.innerHTML = 'Annualized Return';
        const h32 = document.createElement('h3');
        h32.className = 'font-bold text-xl';
        h32.innerHTML += annualized;
        AnnualReturn.appendChild(p2);
        AnnualReturn.appendChild(h32);

        resultLeft.appendChild(p);
        resultLeft.appendChild(h3);
        resultLeft.appendChild(totalIncrease);
        resultLeft.appendChild(AnnualReturn);
        resultTable.appendChild(resultLeft);


        const resultRight = document.createElement('div');
        resultTable.appendChild(resultRight);
        this.resultRight = resultRight;
        this.renderChart(this.resultRight, this.chartData);
        return resultTable;
    }

    getChartOptions(series) {
        // data should be [{x: , y: }]
        return {
            series: [
                {
                    name: 'NEPSE',
                    data: series.prices,
                },
            ],
            chart: {
                type: 'area',
                height: 230,
                stacked: false,
                zoom: {
                    enabled: false,
                },
            },
            dataLabels: {
                enabled: false,
            },
            markers: {
                size: 0,
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 1,
                    inverseColors: false,
                    opacityFrom: 0.7,
                    opacityTo: 0,
                    stops: [0, 90, 100],
                },
            },
            labels: series.dates,
            xaxis: {
                type: 'datetime',
            },
            yaxis: {
                opposite: false,
            },
            legend: {
                horizontalAlign: 'left',
            },
            tooltip: {
                shared: false,
                y: {
                    formatter(val) {
                        return `${val}`;
                    },
                },
            },
        };
    }

    renderChart(chartDiv, apiData) {
        const chart = new Apexcharts(chartDiv, this.getChartOptions(apiData));
        chart.render();
        this.chart = chart;
    }

    showContent() {
        this.mainContainer.innerHTML = '';
        this.mainContainer.classList.add('m-2', 'border-2', 'p-4', 'bg-white', 'dark:bg-dark', 'text-black', 'border-gray-100', 'dark:border-gray-500', 'dark:text-slate-300', 'whatIf-container');
        this.mainContainer.style.width = 'fit-content';
        const container = document.createElement('div');
        container.appendChild(this.createTab());
        container.appendChild(this.createForm(this.apiData.ipo));
        container.className = 'outerContainer';
        this.container = container;
        this.mainContainer.appendChild(container);
    }
}