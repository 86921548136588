import {
  amountAbbreviation,
  commaSeperatedFloats,
  isMoblie,
  NPSTOCKS_LIVE_URL,
} from "../Utils";

export default class KeyMetricsEquity {
  constructor({ token, id, ticker }) {
    this.config = { token, id, ticker };
    this.mainContainer = document.querySelector(id);
    this.mainContainer.innerHTML = "";
    this.style = document.createElement("style");
    this.style.innerHTML = `@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
                    ${id}{font-family: 'Inter', sans-serif;}`;
    this.mainContainer.appendChild(this.style);
    this.data = {};
    this.fetchError = false;
  }

  async fetchData() {
    try {
      await isMoblie({ encyryptedText: this.config.token });
      const data = await fetch(
        `${NPSTOCKS_LIVE_URL}/widget/keyMetricsEquity?ticker=${this.config.ticker}`
      );
      let parsedData = await data.json();
      if (parsedData.code === 500) {
        this.fetchError = true;
        return;
      }
      this.data = parsedData.response;
      this.fetchError = false;
    } catch (e) {
      this.fetchError = true;
    }
  }

  async init() {
    await this.fetchData();
    this.renderContent();
  }

  renderContent() {
    this.mainContainer.className =
      "m-2 h-[241px] rounded-md bg-white p-3 overflow-hidden dark:bg-dark dark:text-darkText";
    this.mainContainer.id = "KeyMetricsEquity";
    const body = document.createElement("div");
    body.className = "flex justify-between";
    body.id = "KeyMetricsEquity-Body";
    this.mainContainer.append(body);
    if (this.fetchError) {
      body.className =
        "flex justify-center items-center dark:text-white/50 text-black/50 h-full";
      body.innerText = "Sorry, an error occured while fetching data.";
    } else {
      this.body();
    }
  }

  body() {
    const body = document.getElementById("KeyMetricsEquity-Body");
    body.append(this.firstColumn(), this.secondColumn(), this.thirdColumn());
  }

  firstColumn() {
    const container = document.createElement("div");
    container.className = "flex flex-col gap-y-5";
    container.append(
      this.element("Open", commaSeperatedFloats(this.data.open)),
      this.element("52 Wk High", commaSeperatedFloats(this.data.fiftyHigh)),
      this.element("EPS (diluted)", commaSeperatedFloats(this.data.epsDiluted)),
      this.element("PB Ratio", commaSeperatedFloats(this.data.pbRatio))
    );
    return container;
  }

  secondColumn() {
    const container = document.createElement("div");
    container.className = "flex flex-col gap-y-5";
    container.append(
      this.element("Market Cap", amountAbbreviation(this.data.marketCap)),
      this.element("52 Wk Low", commaSeperatedFloats(this.data.fiftyLow)),
      this.element("PE (diluted)", commaSeperatedFloats(this.data.peDiluted)),
      this.element("Beta", commaSeperatedFloats(this.data.beta))
    );
    return container;
  }

  thirdColumn() {
    const container = document.createElement("div");
    container.className = "flex flex-col gap-y-5";
    container.append(
      this.element("Turnover", amountAbbreviation(this.data.turnover)),
      this.element("Avg Vol (7d)", amountAbbreviation(this.data.avgVol7d)),
      this.element("ROE", commaSeperatedFloats(this.data.roe * 100) + "%"),
      this.element(
        "Div yields",
        commaSeperatedFloats(this.data.divYield * 100) + "%"
      )
    );
    return container;
  }

  element(name, value) {
    const container = document.createElement("div");
    container.className = "flex flex-col gap-y-1";

    const nameContainer = document.createElement("div");
    nameContainer.className = "text-[14px] font-[600] leading-4";
    nameContainer.innerText = name;

    const valueContainer = document.createElement("div");
    valueContainer.className =
      "text-[14px] font-[500] leading-4 text-[#525B60] dark:text-secDarkText";
    valueContainer.innerText = value ?? "-";

    container.append(nameContainer, valueContainer);

    return container;
  }
}
