import {
  commaSeperatedFloats,
  isMoblie,
  isNegativeNumber,
  NPSTOCKS_LIVE_URL,
} from "../Utils";

export default class Ticker {
  constructor({ token, id }) {
    this.config = { token, id };
    this.mainContainer = document.querySelector(id);
    this.mainContainer.innerHTML = "";
    this.style = document.createElement("style");
    this.style.innerHTML = `@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
                ${id}{font-family: 'Inter', sans-serif;}
                ${id}::-webkit-scrollbar {
                    display: none;
                }
                `;

    this.mainContainer.appendChild(this.style);
    this.data = {};
    this.fetchError = false;
  }

  async fetchData() {
    try {
      await isMoblie({ encyryptedText: this.config.token });
      const data = await fetch(`${NPSTOCKS_LIVE_URL}/widget/stock-live`);
      this.data = await data.json();
      this.data = this.data.response;
      this.fetchError = false;
    } catch (e) {
      console.log("Error Fetching Data", e);
      this.fetchError = true;
    }
  }

  async init() {
    await this.fetchData();
    this.renderContent();
  }

  renderContent() {
    this.mainContainer.className =
      "m-2 flex h-[73px] items-center overflow-x-auto overflow-y-hidden xsm:no-scrollbar rounded-md bg-white py-2 px-3 dark:bg-dark dark:text-darkText";
    if (this.fetchError) {
      const container = document.createElement("div");
      container.className = "flex items-center w-full justify-center";

      const errorMsg = document.createElement("div");
      errorMsg.className = "dark:text-white/50 text-black/50";
      errorMsg.innerText = "Sorry, an error occured while fetching data.";
      container.appendChild(errorMsg);
      this.mainContainer.append(container);
    } else {
      this.mainContainer.onwheel = (evt) => {
        evt.preventDefault();
        this.mainContainer.scrollLeft += evt.deltaY;
      };
      this.bodyContainer();
    }
  }

  bodyContainer() {
    const container = document.createElement("div");
    container.className = "flex items-center";
    this.mainContainer.append(container);
    for (let data of this.data) {
      container.append(
        this.individualElement({
          symbol: data.symbol,
          ltp: data.ltp,
          percentageChange: data.percentageChange,
          pointChange: data.pointChange,
        })
      );
    }
  }

  individualElement({ symbol, ltp, percentageChange, pointChange }) {
    const isNegative = isNegativeNumber(percentageChange);
    const container = document.createElement("div");
    container.className =
      "w-[200px] border-r border-lightBorderColor dark:border-darkBorderColor px-3 py-2";

    const wrapperContainer = document.createElement("div");
    wrapperContainer.className = "flex flex-col gap-2";
    const symbolLtpContainer = document.createElement("div");
    symbolLtpContainer.className = "flex items-center justify-between";

    const symbolContainer = document.createElement("div");
    symbolContainer.className = "text-[16px] font-[700] leading-5";
    symbolContainer.innerText = symbol;

    const ltpContainer = document.createElement("div");
    ltpContainer.className = "text-[16px] font-[400] leading-5";
    ltpContainer.innerText = commaSeperatedFloats(ltp);

    symbolLtpContainer.append(symbolContainer, ltpContainer);

    const pointPercentageContainer = document.createElement("div");
    pointPercentageContainer.className = "flex items-center justify-between";

    const percentageContainer = document.createElement("div");
    percentageContainer.className = "flex items-center gap-1";

    const logoContainer = document.createElement("div");
    logoContainer.innerHTML = isNegative
      ? `<svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.42847 1.12561L7.25378 6.65123L12.5607 1.12561" stroke="#E15562" stroke-width="1.4"/>
    </svg>
    `
      : `<svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.5715 6.87439L6.74622 1.34877L1.43935 6.87439" stroke="#53A895" stroke-width="1.4" />
  </svg>`;

    const percentageValueContainer = document.createElement("div");
    percentageValueContainer.className = "text-[14px] font-[700] leading-4";
    percentageValueContainer.innerText = isNegative
      ? commaSeperatedFloats(percentageChange) + "%"
      : "+" + commaSeperatedFloats(percentageChange) + "%";
    percentageValueContainer.classList.add(
      isNegative ? "text-[#E15562]" : "text-[#53A895]"
    );

    percentageContainer.append(logoContainer, percentageValueContainer);

    const pointContainer = document.createElement("div");
    pointContainer.className = "text-[14px] font-[700] leading-4";
    pointContainer.innerText = isNegative
      ? commaSeperatedFloats(pointChange)
      : "+" + commaSeperatedFloats(pointChange);
    pointContainer.classList.add(
      isNegative ? "text-[#E15562]" : "text-[#53A895]"
    );

    pointPercentageContainer.append(percentageContainer, pointContainer);

    wrapperContainer.append(symbolLtpContainer, pointPercentageContainer);
    container.append(wrapperContainer);

    return container;
  }
}
