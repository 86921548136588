import { isMoblie, isNegativeNumber, NPSTOCKS_LIVE_URL, NPSTOCKS_URL } from "../Utils";

export default class MutualFund {
  constructor({ token, id }) {
    this.config = { token, id };
    this.mainContainer = document.querySelector(id);
    this.mainContainer.innerHTML = "";
    this.style = document.createElement("style");
    this.style.innerHTML = `@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
                ${id}{font-family: 'Inter', sans-serif;}`;
    this.mainContainer.appendChild(this.style);
    this.data = {};
    this.date = new Date();
    this.fetchError = false;
  }

  async fetchData() {
    try {
      await isMoblie({ encyryptedText: this.config.token });
      const data = await fetch(`${NPSTOCKS_LIVE_URL}/widget/mutual-fund`);
      let parsedData = await data.json();
      parsedData = parsedData.response;
      this.date = new Date(parsedData.date);
      this.data["topHoldings"] = parsedData.topHoldings;
      this.data["topHoldings"].forEach((data) => {
        return {
          ...data,
          mutualFund: data.mutualFund.sort((a, b) => {
            return b.amount - a.amount;
          }),
        };
      });
      this.data["topBought"] = parsedData.topBought;
      this.data["topBought"].forEach((data) => {
        return {
          ...data,
          mutualFund: data.mutualFund.sort((a, b) => {
            return b.amount - a.amount;
          }),
        };
      });
      this.data["topSold"] = parsedData.topSold;
      this.data["topSold"].forEach((data) => {
        return {
          ...data,
          mutualFund: data.mutualFund.sort((a, b) => {
            return a.amount - b.amount;
          }),
        };
      });
      this.fetchError = false;
    } catch (e) {
      console.log("Error Fetching Mutual-Fund Data", e);
      this.fetchError = true;
    }
  }

  async init() {
    await this.fetchData();
    this.renderContent();
  }

  renderContent() {
    this.mainContainer.classList.add(
      "h-[739px]",
      "min-w-[356px]",
      "overflow-hidden",
      "rounded-[10px]",
      "bg-white",
      "dark:bg-dark",
      "dark:text-white",
      "m-2"
    );
    const bodyContainer = document.createElement("div");
    bodyContainer.id = "Mutual-Fund-Body-Container";
    bodyContainer.classList.add("overflow-y-auto", "max-h-[679px]", "xsm:scrollbar");
    this.mainContainer.append(this.headerContainer(), bodyContainer);
    if (!this.fetchError) {
      this.topHoldingsContainer();
    } else {
      this.errorContainer({ calledFrom: "" });
    }
  }

  errorContainer({ calledFrom = "" }) {
    const bodyContainer = document.getElementById("Mutual-Fund-Body-Container");
    bodyContainer.classList.add(
      "h-[679px]",
      "flex",
      "justify-center",
      "items-center"
    );
    bodyContainer.innerHTML =
      calledFrom === ""
        ? `<div class="dark:text-white/50 text-black/50">
    Sorry, an error occured while fetching data.
  </div>`
        : `<div class="dark:text-white/50 text-black/50">
  Sorry, No Data Found.
</div>`;
    this.mainContainer.append(bodyContainer);
  }

  headerContainer() {
    const containerDiv = document.createElement("div");
    containerDiv.classList.add(
      "py-[9px]",
      "border-b",
      "dark:border-white/20",
      "p-[10px]"
    );

    const dateContainerDiv = document.createElement("div");
    dateContainerDiv.classList.add(
      "text-right",
      "text-[10px]",
      "font-[400]",
      "leading-[12px]",
      "text-clickAbleLinkColor",
      "dark:text-cyan-500",
      "select-none"
    );
    dateContainerDiv.innerText = `as on ${this.date.getFullYear()}/${
      this.date.getMonth() + 1
    }/${this.date.getDate()}`;

    const tabsContainer = document.createElement("div");
    tabsContainer.classList.add(
      "mt-[7px]",
      "flex",
      "items-center",
      "xsm:justify-start",
      "xsm:gap-x-5",
      "justify-between"
    );

    const btnHoldings = document.createElement("div");
    btnHoldings.classList.add(
      "rounded-[5px]",
      "bg-btnActiveColor",
      "px-[20px]",
      "py-[4px]",
      "text-[12px]",
      "font-[400]",
      "leading-[15px]",
      "text-white",
      // "cursor-pointer",
      "select-none"
    );
    btnHoldings.id = "btnHoldings";
    btnHoldings.innerText = "Top Holdings";
    btnHoldings.onclick = () => {
      this.tabChanger("btnHoldings");
    };

    const btnTopBought = document.createElement("div");
    btnTopBought.classList.add(
      "rounded-[5px]",
      "hover:bg-btnActiveColor/50",
      "px-[20px]",
      "py-[4px]",
      "text-[12px]",
      "font-[400]",
      "leading-[15px]",
      "hover:text-white",
      // "cursor-pointer",
      "select-none"
    );
    btnTopBought.id = "btnTopBought";
    btnTopBought.innerText = "Top Bought";
    btnTopBought.onclick = () => {
      this.tabChanger("btnTopBought");
    };

    const btnTopSold = document.createElement("div");
    btnTopSold.classList.add(
      "rounded-[5px]",
      "hover:bg-btnActiveColor/50",
      "px-[20px]",
      "py-[4px]",
      "text-[12px]",
      "font-[400]",
      "leading-[15px]",
      "hover:text-white",
      // "cursor-pointer",
      "select-none"
    );
    btnTopSold.id = "btnTopSold";
    btnTopSold.innerText = "Top Sold";
    btnTopSold.onclick = () => {
      this.tabChanger("btnTopSold");
    };

    tabsContainer.append(btnHoldings, btnTopBought, btnTopSold);

    containerDiv.append(dateContainerDiv, tabsContainer);

    return containerDiv;
  }

  tabChanger(tabClass) {
    const unSelectedStyle =
      // "rounded-[5px] px-[20px] py-[4px] text-[12px] font-[400] leading-[15px] hover:bg-btnActiveColor/50 hover:text-white cursor-pointer select-none";
      "rounded-[5px] px-[20px] py-[4px] text-[12px] font-[400] leading-[15px] hover:bg-btnActiveColor/50 hover:text-white select-none";
    const selectedStyle =
      // "rounded-[5px] bg-btnActiveColor px-[20px] py-[4px] text-[12px] font-[400] leading-[15px] text-white cursor-pointer select-none";
      "rounded-[5px] bg-btnActiveColor px-[20px] py-[4px] text-[12px] font-[400] leading-[15px] text-white select-none";
    const btn = document.getElementById(tabClass);
    const bodyContainer = document.getElementById("Mutual-Fund-Body-Container");
    bodyContainer.innerHTML = "";
    if (btn.id === "btnHoldings") {
      const btnTopBought = document.getElementById("btnTopBought");
      const btnTopSold = document.getElementById("btnTopSold");
      btnTopBought.className = btnTopSold.className = unSelectedStyle;
      if (!this.fetchError) {
        this.topHoldingsContainer();
      } else {
        this.errorContainer({ calledFrom: "" });
      }
    } else if (btn.id === "btnTopBought") {
      const btnTopHoldings = document.getElementById("btnHoldings");
      const btnTopSold = document.getElementById("btnTopSold");
      btnTopHoldings.className = btnTopSold.className = unSelectedStyle;
      if (!this.fetchError) {
        this.topBoughtContainer();
      } else {
        this.errorContainer({ calledFrom: "" });
      }
    } else if (btn.id === "btnTopSold") {
      const btnTopHoldings = document.getElementById("btnHoldings");
      const btnTopBought = document.getElementById("btnTopBought");
      btnTopHoldings.className = btnTopBought.className = unSelectedStyle;
      if (!this.fetchError) {
        this.topSoldContainer();
      } else {
        this.errorContainer({ calledFrom: "" });
      }
    }
    btn.className = selectedStyle;
  }

  topHoldingsContainer() {
    const bodyContainer = document.getElementById("Mutual-Fund-Body-Container");
    const bodyHeading = document.createElement("div");
    bodyHeading.classList.add(
      "grid",
      "h-[53px]",
      "grid-cols-[1fr,.4fr,.3fr;]",
      "pt-[5px]",
      "pl-[20px]",
      "pr-[17px]"
    );

    const gridOne = document.createElement("div");
    const gridTwo = document.createElement("div");
    const gridThree = document.createElement("div");
    gridTwo.className = gridThree.className =
      "text-[10px] font-[400] leading-[12px] text-titleLabelDarkText";
    gridTwo.innerText = "Total Units";
    gridThree.classList.add("text-right");
    gridThree.innerText = "% of Equity Portfolio";
    bodyHeading.append(gridOne, gridTwo, gridThree);
    bodyContainer.append(bodyHeading);

    for (let i = 0; i < this.data.topHoldings.length; i++) {
      bodyContainer.append(
        this.elementList(
          this.data.topHoldings[i].symbol,
          this.data.topHoldings[i].company,
          this.data.topHoldings[i].totalUnits,
          this.data.topHoldings[i].equityPercentange.toFixed(2),
          "topHoldings"
        ),
        this.childElementContainer(
          this.data.topHoldings[i].symbol,
          this.data.topHoldings[i].mutualFund,
          "topHoldings"
        )
      );
    }
  }

  topBoughtContainer() {
    const bodyContainer = document.getElementById("Mutual-Fund-Body-Container");
    const bodyHeading = document.createElement("div");
    bodyHeading.classList.add(
      "grid",
      "h-[53px]",
      "grid-cols-[1fr,.4fr]",
      "pt-[5px]",
      "pl-[20px]",
      "pr-[17px]"
    );

    const gridOne = document.createElement("div");
    const gridTwo = document.createElement("div");
    gridTwo.className =
      "text-[10px] font-[400] leading-[12px] text-titleLabelDarkText text-right";
    gridTwo.innerText = "Total Units Bought";

    bodyHeading.append(gridOne, gridTwo);
    bodyContainer.append(bodyHeading);
    
    for (let i = 0; i < this.data.topBought.length; i++) {
      bodyContainer.append(
        this.elementList(
          this.data.topBought[i].symbol,
          this.data.topBought[i].company,
          this.data.topBought[i].totalUnits,
          this.data.topBought[i].equityPercentange
        ),
        this.childElementContainer(
          this.data.topBought[i].symbol,
          this.data.topBought[i].mutualFund
        )
      );
    }
  }
  topSoldContainer() {
    const bodyContainer = document.getElementById("Mutual-Fund-Body-Container");
    const bodyHeading = document.createElement("div");
    bodyHeading.classList.add(
      "grid",
      "h-[53px]",
      "grid-cols-[1fr,.4fr]",
      "pt-[5px]",
      "pl-[20px]",
      "pr-[17px]"
    );

    const gridOne = document.createElement("div");
    const gridTwo = document.createElement("div");
    gridTwo.className =
      "text-[10px] font-[400] leading-[12px] text-titleLabelDarkText text-right";
    gridTwo.innerText = "Total Units Sold";

    bodyHeading.append(gridOne, gridTwo);
    bodyContainer.append(bodyHeading);

    for (let i = 0; i < this.data.topSold.length; i++) {
      bodyContainer.append(
        this.elementList(
          this.data.topSold[i].symbol,
          this.data.topSold[i].company,
          this.data.topSold[i].totalUnits,
          this.data.topSold[i].equityPercentange,
          "topSold"
        ),
        this.childElementContainer(
          this.data.topSold[i].symbol,
          this.data.topSold[i].mutualFund
        )
      );
    }
  }

  elementList(tiker, tikerNameFull, amountValue, percentage, calledFrom) {
    const containerDiv = document.createElement("div");
    containerDiv.classList.add(
      "pl-[20px]",
      "pr-[17px]",
      "hover:bg-lightHoverColor",
      // "cursor-pointer",
      "dark:hover:bg-slate-800",
      "dark:text-darkText"
    );

    const gridContainer = document.createElement("div");
    if (calledFrom === "topHoldings") {
      gridContainer.classList.add(
        "grid",
        "py-[8px]",
        "grid-cols-[1fr,.4fr,.3fr;]",
        "items-center",
        "border-b",
        "border-lightBorderColor",
        "dark:border-darkBorderColor"
      );
    } else {
      gridContainer.classList.add(
        "grid",
        "py-[8px]",
        "grid-cols-[1fr,.4fr]",
        "items-center",
        "border-b",
        "border-lightBorderColor",
        "dark:border-darkBorderColor"
      );
    }

    const nameContainerDiv = document.createElement("div");

    const tikerName = document.createElement("div");
    tikerName.classList.add("text-[14px]", "font-[400]", "leading-[17px]");
    tikerName.innerText = tiker;

    const tikerFullName = document.createElement("div");
    tikerFullName.classList.add(
      "mt-[3px]",
      "text-[10px]",
      "font-[400]",
      "leading-[12px]",
      "text-secDarkText"
    );
    tikerFullName.innerText = tikerNameFull;

    nameContainerDiv.append(tikerName, tikerFullName);

    const value = document.createElement("div");
    value.classList.add("text-[14px]", "font-[400]", "leading-[17px]");

    const percentageDiv = document.createElement("div");
    percentageDiv.classList.add(
      "text-[14px]",
      "font-[400]",
      "leading-[17px]",
      "text-right"
    );
    if (calledFrom === "topHoldings") {
      value.innerText = amountValue.toLocaleString();
    } else if (calledFrom === "topSold") {
      value.classList.add("text-right");
      value.innerText = "-" + amountValue.toLocaleString();
    } else {
      value.classList.add("text-right");
      if (isNegativeNumber(amountValue)) {
        value.innerText = amountValue.toLocaleString();
      } else {
        value.innerText = "+" + amountValue.toLocaleString();
      }
    }
    percentageDiv.innerText = percentage + "%";

    if (calledFrom === "topHoldings") {
      gridContainer.append(nameContainerDiv, value, percentageDiv);
    } else {
      gridContainer.append(nameContainerDiv, value);
    }
    containerDiv.appendChild(gridContainer);
    containerDiv.onclick = () => {
      const childElement = document.getElementById(tiker);
      if (childElement.classList.contains("block")) {
        childElement.classList.remove("block");
        childElement.classList.add("hidden");
        containerDiv.classList.remove("bg-lightHoverColor");
        containerDiv.classList.remove("dark:bg-slate-800");
      } else {
        containerDiv.classList.add("bg-lightHoverColor");
        containerDiv.classList.add("dark:bg-slate-800");
        childElement.classList.add("block");
        childElement.classList.remove("hidden");
      }
    };
    return containerDiv;
  }

  childElementContainer(id, subElementObject, calledFrom) {
    const containerDiv = document.createElement("div");
    containerDiv.classList.add("pl-[20px]", "pr-[17px]", "pb-[13px]", "hidden");
    containerDiv.id = id;
    for (let i = 0; i < subElementObject.length; i++) {
      if (subElementObject[i].amount === 0) {
        continue;
      }
      containerDiv.append(
        this.childElement(
          subElementObject[i].company,
          subElementObject[i].amount,
          calledFrom
        )
      );
    }
    return containerDiv;
  }

  childElement(nameOfChild, amount, calledFrom) {
    const containerDiv = document.createElement("div");
    containerDiv.classList.add("mt-[12px]", "flex", "justify-between");

    const childName = document.createElement("div");
    childName.classList.add(
      "text-[12px]",
      "font-[400]",
      "leading-[15px]",
      "text-clickAbleLinkColor",
      "dark:text-cyan-500"
    );
    childName.innerText = nameOfChild;

    const amountDiv = document.createElement("div");
    amountDiv.classList.add(
      "text-[12px]",
      "font-[700]",
      "leading-[15px]",
      "dark:text-darkText"
    );

    if (!(calledFrom === "topHoldings")) {
      if (isNegativeNumber(amount)) {
        amountDiv.classList.add("text-red");
        amountDiv.innerText = amount.toLocaleString();
      } else {
        amountDiv.classList.add("text-darkGreen");
        amountDiv.innerText = "+" + amount.toLocaleString();
      }
    } else {
      amountDiv.innerText = amount.toLocaleString();
    }

    containerDiv.append(childName, amountDiv);

    return containerDiv;
  }
}
