import {validateToken} from '../Auth';
import {createSpinner} from '../Utils/UI';
import {NPSTOCKS_URL} from '../Utils';

export default class TickerTape {
    constructor({token, id}) {
        this.config = {token, id};
        this.mainContainer = document.querySelector(id);
    }

    async init() {
        const isOk = await validateToken(this.config.token, this.mainContainer);
        if (!isOk) {
            return;
        }
        this.showContent();
    }

    async fetchData() {
        try {
            this.mainContainer.appendChild(createSpinner());
            const data = await fetch(`${NPSTOCKS_URL}/tickerTape`);
            this.apiData = await data.json();
        } catch (e) {
            console.log(e);
        }
    }

    showContent() {
        this.mainContainer.innerHTML = '';
        this.mainContainer.classList.add('m-2', 'border', 'p-4', 'h-16', 'rounded', 'bg-white', 'dark:bg-dark', 'text-black', 'border-gray-200', 'dark:border-gray-500', 'dark:text-slate-300');
        this.mainContainer.appendChild(this.createTapeContainer());
    }

    createTapeContainer() {
        const div = document.createElement('div');
        div.className = 'flex justify-center divide-x';
        div.innerHTML = 'sab';
        return div;
    }
}
