import {
  commaSeperatedFloats,
  isMoblie,
  isNegativeNumber,
  NPSTOCKS_LIVE_URL,
  NPSTOCKS_URL,
} from "../Utils";

export default class SymbolInfoSmall {
  constructor({ token, id, tiker }) {
    this.config = { token, id };
    this.tiker = tiker;
    this.mainContainer = document.querySelector(id);
    this.style = document.createElement("style");
    this.style.innerHTML = `@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
        ${id}{font-family: 'Inter', sans-serif;}`;
    this.mainContainer.appendChild(this.style);
    this.data = {};
    this.isNegative = false;
    this.fetchError = false;
  }

  async init() {
    await this.fetchData();
    this.renderContent();
  }

  async fetchData() {
    try {
      await isMoblie({ encyryptedText: this.config.token });
      const data = await fetch(
        `${NPSTOCKS_LIVE_URL}/widget/stockInfo?ticker=${this.tiker}`
      );
      this.apiData = await data.json();
      this.data = this.apiData.response;
      this.isNegative = isNegativeNumber(this.data.pointChange);
      this.fetchError = false;
    } catch (e) {
      console.log(e);
      this.fetchError = true;
    }
  }

  renderContent() {
    this.mainContainer.classList.add(
      "flex",
      "m-2",
      "h-[95px]",
      // "w-[349px]",
      // "cursor-pointer",
      "overflow-hidden",
      "rounded-md",
      "border-2",
      "bg-white",
      "pt-3",
      "pl-4",
      "flex-col",
      "box-border",
      "pr-3",
      "dark:bg-dark",
      "dark:text-darkText"
    );
    if (this.fetchError) {
      const error = document.createElement("div");
      error.className =
        "dark:text-white/50 text-black/50 flex justify-center items-center h-full";
      error.innerText = "Failed to fetch data.";
      this.mainContainer.append(error);
      this.mainContainer.classList.remove("pr-3", "pl-4", "pt-3");
    } else {
      this.mainContainer.append(
        this.topRow(this.data.symbol, this.data.company),
        this.bottomRow(
          commaSeperatedFloats(this.data.ltp),
          commaSeperatedFloats(this.data.percentageChange),
          commaSeperatedFloats(this.data.pointChange)
        )
      );
    }
  }

  topRow(tiker, fullName) {
    const containerDiv = document.createElement("div");
    containerDiv.classList.add("flex", "justify-between");
    const tikerDiv = document.createElement("div");
    tikerDiv.classList.add("text-[18px]", "font-[600]", "leading-[21.78px]");
    tikerDiv.innerText = tiker;
    const tikerFullName = document.createElement("div");
    tikerFullName.classList.add(
      "text-[10px]",
      "font-[400]",
      "leading-[12.1px]",
      "text-titleLabelDarkText",
      "mt-[6px]",
      "mr-[6px]"
    );
    tikerFullName.innerText = fullName;

    containerDiv.append(tikerDiv, tikerFullName);
    return containerDiv;
  }

  bottomRow(ltp, percentage, point) {
    const containerDiv = document.createElement("div");
    containerDiv.classList.add("flex", "justify-between");
    const ltpDiv = document.createElement("div");
    ltpDiv.classList.add(
      "mb-[14px]",
      "text-[32px]",
      "font-[700]",
      "leading-[38.73px]",
      "mt-[9px]"
    );
    ltpDiv.innerText = ltp;
    containerDiv.append(ltpDiv, this.percentageAndPoint(percentage, point));
    return containerDiv;
  }

  percentageAndPoint(percentage, points) {
    const containerDiv = document.createElement("div");
    containerDiv.classList.add("pt-[20px]");
    if (!this.isNegative) {
      containerDiv.innerHTML = `<svg
          class="inline mb-[5px]"
          width="14"
          height="8"
          viewBox="0 0 14 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.5718 6.87439L6.74646 1.34877L1.43959 6.87439"
            stroke="#53A895"
            stroke-width="1.4"
          />
        </svg>`;
    } else {
      containerDiv.innerHTML = `<svg  class="inline mb-[5px]" width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.42847 1.12561L7.25378 6.65123L12.5607 1.12561" stroke="#E15562" stroke-width="1.4"/>
      </svg>`;
    }

    const percentageContainer = document.createElement("span");
    percentageContainer.classList.add(
      "text-[14px]",
      "font-[700]",
      "leading-[16.94px]"
    );
    // percentageContainer.innerText = " " + percentage + "% ";
    const pointContainer = document.createElement("span");
    pointContainer.classList.add(
      "text-[14px]",
      "font-[700]",
      "leading-[16.94px]",
      "ml-1"
    );
    // pointContainer.innerText = points;
    if (this.isNegative) {
      percentageContainer.classList.add("text-red");
      percentageContainer.innerText = " " + percentage + "% ";
      pointContainer.innerText = points;
      pointContainer.classList.add("text-red");
    } else {
      percentageContainer.classList.add("text-darkGreen");
      percentageContainer.innerText = " +" + percentage + "% ";
      pointContainer.innerText = "+" + points;
      pointContainer.classList.add("text-darkGreen");
    }
    containerDiv.append(percentageContainer, pointContainer);
    return containerDiv;
  }
}
