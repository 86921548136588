import * as LightweightCharts from 'lightweight-charts';
import {isMoblie, NPSTOCKS_LIVE_URL} from '../Utils';
import {validateToken} from '../Auth';

export default class TradingviewLightWeightChart {
    constructor({
                    token, id, ticker, height, width,
                    timeseries,
                }) {
        this.config = {
            token,
            id,
            ticker: ticker ?? 'NEPSE',
            height,
            width,
            timeseries,
        };
        this.mainContainer = document.querySelector(id);
    }

    async init() {
        const isOk = await validateToken(this.config.token, this.mainContainer);
        if (!isOk) {
            return;
        }
        await this.fetchData(this.config.ticker);
        this.displayChart();
    }

    async fetchData(ticker) {
        try {
            await isMoblie({ encyryptedText: this.config.token });
            const data = await fetch(`${NPSTOCKS_LIVE_URL}/widget/timeseries-tradingview?period=${this.config.timeseries}&ticker=${ticker}`);
            const parsedData = await data.json();
            // to be changed after....
            this.apiData = parsedData.response;
        } catch (e) {
            console.log(e);
        }
    }

    updateHeightWidth(height, width) {
        this.chart.resize(
            width,
            height,
        );
    }

    displayChart() {
        this.mainContainer.classList.add('m-2', 'border-2', 'p-4', 'bg-white', 'dark:bg-dark', 'text-black', 'dark:text-slate-300');
        const chart = LightweightCharts.createChart(this.mainContainer);
        chart.timeScale().fitContent();
        const addLineSeries = chart.addLineSeries();
        this.lineSeries = addLineSeries;
        this.chart = chart;
        addLineSeries.setData(this.apiData);
        this.chart.applyOptions({
            width: this.config.width,
            height: this.config.height,
        });
    }
}
