import { validateToken } from "../Auth";
import { commaSeperatedFloats, isMoblie, NPSTOCKS_LIVE_URL } from "../Utils";

export default class Index {
  constructor({ token, id, ticker }) {
    this.config = { token, id, ticker };
    this.mainContainer = document.querySelector(id);
    this.mainContainer.innerHTML = "";
    this.style = document.createElement("style");
    this.style.innerHTML = `@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
                        ${id}{font-family: 'Inter', sans-serif;}`;
    this.mainContainer.appendChild(this.style);
    this.data = {};
    this.fetchError = false;
    this.activeButton = "BUY SIDE";
    this.activeButtonStyle =
      "rounded-md bg-[#624BFE] px-12 py-2 text-[13px] font-[600] leading-4 text-white";
    this.inactiveButtonStyle =
      "rounded-md px-12 py-2 text-[13px] font-[600] leading-4 text-[#2d364880] dark:text-white";
  }

  async fetchData() {
    try {
      await isMoblie({ encyryptedText: this.config.token });
      const data = await fetch(
        `${NPSTOCKS_LIVE_URL}/widget/activeBrokers?ticker=${this.config.ticker}`
      );
      const jsonData = await data.json();
      this.data = jsonData.response;
      this.fetchError = false;
    } catch (e) {
      console.log(e);
      this.fetchError = true;
    }
  }

  async init() {
    // const isOk = await validateToken(this.config.token, this.mainContainer);
    // if (!isOk) {
    //   return;
    // }
    await this.fetchData();
    this.showContent();
  }

  showContent() {
    this.mainContainer.className =
      "m-2 h-[253px] overflow-hidden rounded-md bg-white px-1 py-2 dark:bg-dark dark:text-darkText";
    const body = document.createElement("div");
    body.className = "mt-2 h-[83%] overflow-auto xsm:scrollbar";
    body.append(this.titleContainer());
    this.mainContainer.append(this.nav(), body);
    const dataBody = document.createElement("div");
    dataBody.id = "ActiveBroker-Body";
    body.append(dataBody);
    if (this.fetchError) {
      this.errorContainer({ calledFrom: "" });
    } else {
      for (let data of this.data.buy) {
        dataBody.append(
          this.elementContainer(data.broker, data.quantity, data.averagePrice)
        );
      }
    }
  }

  errorContainer({ calledFrom = "" }) {
    const bodyContainer = document.getElementById("ActiveBroker-Body");
    bodyContainer.classList.add(
      "h-[83%]",
      "flex",
      "justify-center",
      "items-center"
    );
    bodyContainer.innerHTML =
      calledFrom === ""
        ? `<div class="dark:text-white/50 text-black/50">
    Failed to fetch Data.
  </div>`
        : `<div class="dark:text-white/50 text-black/50">
  Sorry, No Data Found.
</div>`;
    // this.mainContainer.append(bodyContainer);
  }

  nav() {
    const container = document.createElement("nav");
    container.className =
      "xsm:justify-start xsm:gap-5 flex items-center justify-between";

    const buyContainer = document.createElement("div");
    buyContainer.className = this.activeButtonStyle;
    buyContainer.id = buyContainer.innerText = "BUY SIDE";
    buyContainer.onclick = () => {
      const body = document.getElementById("ActiveBroker-Body");
      body.innerHTML = "";
      buyContainer.className = this.activeButtonStyle;
      this.activeButton = buyContainer.id;
      sellContainer.className = this.inactiveButtonStyle;
      if (this.fetchError) {
        this.errorContainer({ calledFrom: "" });
      } else {
        for (let data of this.data.buy) {
          body.append(
            this.elementContainer(data.broker, data.quantity, data.averagePrice)
          );
        }
      }
    };

    const sellContainer = document.createElement("div");
    sellContainer.className = this.inactiveButtonStyle;
    sellContainer.innerText = "SELL SIDE";
    sellContainer.onclick = () => {
      const body = document.getElementById("ActiveBroker-Body");
      body.innerHTML = "";
      sellContainer.className = this.activeButtonStyle;
      this.activeButton = buyContainer.id;
      buyContainer.className = this.inactiveButtonStyle;
      if (this.fetchError) {
        this.errorContainer({ calledFrom: "" });
      } else {
        for (let data of this.data.sell) {
          body.append(
            this.elementContainer(data.broker, data.quantity, data.averagePrice)
          );
        }
      }
    };

    container.append(buyContainer, sellContainer);

    return container;
  }

  titleContainer() {
    const container = document.createElement("div");
    container.className =
      "sticky top-0 grid grid-cols-[1fr_1fr_.8fr] xsm:grid-cols-[1.2fr_1fr_.5fr] bg-white px-3 pb-[4px] dark:bg-dark";
    for (let element of ["Broker", "Quantity", "Avg. Price"]) {
      const elementContainer = document.createElement("div");
      elementContainer.className = "text-[14px] font-[600] leading-4";
      elementContainer.innerText = element;
      container.append(elementContainer);
    }
    return container;
  }

  elementContainer(broker, quanity, avgPrice) {
    const container = document.createElement("div");
    container.className =
      "grid grid-cols-[1fr_1fr_.8fr] xsm:grid-cols-[1.2fr_1fr_.5fr] border-b border-lightBorderColor dark:border-darkBorderColor px-3 py-2 text-[#525B60] hover:bg-lightHoverColor dark:text-darkText dark:hover:bg-slate-800";
    for (let value of [broker, quanity, avgPrice]) {
      const element = document.createElement("div");
      element.className = "text-[14px] font-[400] leading-4";
      if (value === quanity) {
        element.innerText =
          value === null ? "-" : value.toLocaleString("en-US");
      } else if (value === avgPrice) {
        element.innerText =
          value === null ? "-" : `Rs. ${commaSeperatedFloats(value)}`;
      } else {
        element.innerText = value ?? "-";
      }
      container.append(element);
    }
    return container;
  }
}
