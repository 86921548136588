import { validateToken } from "../Auth";
import {
  commaSeperatedFloats,
  getHours,
  isMoblie,
  isNegativeNumber,
  MONTHS,
  NPSTOCKS_LIVE_URL,
} from "../Utils";

export default class GainerLoserTab {
  constructor({ token, id }) {
    this.config = { token, id };
    this.mainContainer = document.querySelector(id);
    this.mainContainer.innerHTML = "";
    this.style = document.createElement("style");
    this.style.innerHTML = `@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
                    ${id}{font-family: 'Inter', sans-serif;}`;
    this.mainContainer.appendChild(this.style);
    this.data = {};
    this.date = new Date();
    this.fetchError = false;
    this.activeButton = "gainers";
  }

  async init() {
    // const isOk = await validateToken(this.config.token, this.mainContainer);
    // if (!isOk) {
    //   return;
    // }
    await this.fetchData();
    this.showContent();
  }

  async fetchData() {
    try {
      await isMoblie({ encyryptedText: this.config.token });
      const data = await fetch(`${NPSTOCKS_LIVE_URL}/widget/gainerLoser`);
      const response = await data.json();
      this.data = response.response;
      this.date = new Date(this.data.gainers[0].timestamp);
      this.fetchError = false;
    } catch (e) {
      console.log(e);
      this.fetchError = true;
    }
  }

  showContent() {
    this.mainContainer.classList.add(
      "rounded-md",
      "bg-white",
      "py-2",
      "m-2",
      "h-[465px]",
      "overflow-hidden",
      "dark:bg-dark",
      "dark:text-white"
    );
    if (!this.fetchError) {
      this.mainContainer.append(
        this.header(),
        this.buttons(this.activeButton),
        this.line(),
        this.body()
      );
    } else {
      // this.mainContainer.classList.remove("max-h-[465px]");
      // this.mainContainer.classList.add("h-[465px]");
      this.mainContainer.append(
        this.header(),
        this.buttons(this.activeButton),
        this.line(),
        this.errorBody()
      );
    }
  }

  header() {
    const dateContainer = document.createElement("div");
    dateContainer.classList.add("mb-1", "flex", "justify-end", "px-6");
    const date = document.createElement("div");
    date.classList.add(
      "text-[10px]",
      "font-[400]",
      "leading-3",
      "dark:text-secDarkText"
    );
    // date.innerText = `as on June 30, 2022 1:01 PM`;

    date.innerText = `as on ${
      MONTHS[this.date.getMonth()]
    } ${this.date.getDate()}, ${this.date.getFullYear()} ${getHours(
      this.date
    )}`;
    dateContainer.append(date);
    return dateContainer;
  }

  buttons() {
    const container = document.createElement("div");
    container.classList.add(
      "flex",
      "justify-between",
      "mb-1",
      "px-3",
      "xsm:justify-start",
      "gap-5"
    );
    const gainer = document.createElement("div");
    gainer.classList.add(
      "rounded-[5px]",
      "bg-btnActiveColor",
      "px-[20px]",
      "py-[4px]",
      "text-[12px]",
      "font-[400]",
      "leading-[15px]",
      "text-white",
      // "cursor-pointer",
      "select-none"
    );
    gainer.innerText = "Gainers";
    gainer.id = "gainers";
    const losers = document.createElement("div");
    losers.innerText = "Losers";
    losers.classList.add(
      "rounded-[5px]",
      "hover:bg-btnActiveColor/50",
      "px-[20px]",
      "py-[4px]",
      "text-[12px]",
      "font-[400]",
      "leading-[15px]",
      "hover:text-white",
      // "cursor-pointer",
      "select-none"
    );
    losers.id = "losers";
    const active = document.createElement("div");
    active.innerText = "Active";
    active.id = "active";
    active.classList.add(
      "rounded-[5px]",
      "hover:bg-btnActiveColor/50",
      "px-[20px]",
      "py-[4px]",
      "text-[12px]",
      "font-[400]",
      "leading-[15px]",
      "hover:text-white",
      // "cursor-pointer",
      "select-none"
    );
    gainer.onclick = () => {
      this.tabChanger("gainers");
    };
    losers.onclick = () => {
      this.tabChanger("losers");
    };
    active.onclick = () => {
      this.tabChanger("active");
    };
    container.append(gainer, losers, active);
    return container;
  }

  line() {
    const container = document.createElement("div");
    container.classList.add("border-b", "dark:border-white/20");
    return container;
  }
  tabChanger(tabName) {
    this.activeButton = tabName;
    const body = document.getElementById("data-container-gainer-loser");
    body.innerHTML = "";
    const unSelectedStyle =
      // "rounded-[5px] px-[20px] py-[4px] text-[12px] font-[400] leading-[15px] hover:bg-btnActiveColor/50 hover:text-white cursor-pointer select-none";
      "rounded-[5px] px-[20px] py-[4px] text-[12px] font-[400] leading-[15px] hover:bg-btnActiveColor/50 hover:text-white select-none";
    const selectedStyle =
      // "rounded-[5px] bg-btnActiveColor px-[20px] py-[4px] text-[12px] font-[400] leading-[15px] text-white cursor-pointer select-none";
      "rounded-[5px] bg-btnActiveColor px-[20px] py-[4px] text-[12px] font-[400] leading-[15px] text-white select-none";
    const btn = document.getElementById(tabName);
    if (btn.id === "gainers") {
      const losers = document.getElementById("losers");
      const active = document.getElementById("active");
      losers.className = active.className = unSelectedStyle;
      if (!this.fetchError) {
        body.innerHTML = "";
        this.data["gainers"].map((data) => {
          body.append(
            this.elementData(
              data.ticker,
              data.ltp,
              data.stockFullName,
              data.pointChange,
              data.pc
            )
          );
        });
      } else {
        body.append(this.errorBody());
      }
    } else if (btn.id === "losers") {
      body.innerHTML = "";
      const gainers = document.getElementById("gainers");
      const active = document.getElementById("active");
      gainers.className = active.className = unSelectedStyle;
      if (!this.fetchError) {
        this.data["losers"].map((data) => {
          body.append(
            this.elementData(
              data.ticker,
              data.ltp,
              data.stockFullName,
              data.pointChange,
              data.pc
            )
          );
        });
      } else {
        body.append(this.errorBody());
      }
    } else if (btn.id === "active") {
      body.innerHTML = "";
      const gainers = document.getElementById("gainers");
      const losers = document.getElementById("losers");
      gainers.className = losers.className = unSelectedStyle;
      if (!this.fetchError) {
        this.data["active"].map((data) => {
          body.append(
            this.elementData(
              data.ticker,
              data.ltp,
              data.stockFullName
              //   data.pointChange,
              //   data.pc
            )
          );
        });
      } else {
        body.append(this.errorBody());
      }
    }
    btn.className = selectedStyle;
  }
  errorBody() {
    const dataContainer = document.createElement("div");
    dataContainer.id = "data-container-gainer-loser";
    dataContainer.classList.add(
      "flex",
      "justify-center",
      "items-center",
      "h-full",
      "text-black/30",
      "dark:text-white/30",
      "overflow-auto"
    );
    dataContainer.innerText = "Unable to fetch data.";
    return dataContainer;
  }

  body() {
    const dataContainer = document.createElement("div");
    dataContainer.id = "data-container-gainer-loser";
    dataContainer.classList.add("overflow-auto", "xsm:scrollbar", "h-[91%]");
    this.data["gainers"].map((data) => {
      dataContainer.append(
        this.elementData(
          data.ticker,
          data.ltp,
          data.stockFullName,
          data.pointChange,
          data.pc
        )
      );
    });
    return dataContainer;
  }

  elementData(symbol, ltp, volume, pointChange, percentageChange) {
    const container = document.createElement("div");
    container.classList.add(
      // "cursor-pointer",
      "px-6",
      "hover:bg-lightHoverColor",
      "dark:hover:bg-slate-800"
    );
    const dataContainer = document.createElement("div");
    dataContainer.classList.add(
      "border-b",
      "border-lightBorderColor",
      "dark:border-darkBorderColor",
      "py-2"
    );
    dataContainer.append(
      this.symbolLtp(symbol, ltp),
      this.volumeChange(volume, pointChange, percentageChange)
    );
    container.append(dataContainer);
    return container;
  }

  symbolLtp(symbol, ltp) {
    const container = document.createElement("div");
    container.classList.add("flex", "justify-between", "dark:text-darkText");
    const symbolContainer = document.createElement("div");
    symbolContainer.classList.add("text-[14px]", "font-[400]", "leading-4");
    symbolContainer.innerText = symbol;
    const ltpContainer = document.createElement("div");
    ltpContainer.classList.add("text-[14px]", "font-[400]", "leading-4");
    ltpContainer.innerText = commaSeperatedFloats(ltp);
    container.append(symbolContainer, ltpContainer);
    return container;
  }

  volumeChange(fullName, pointChange, percentageChange) {
    const container = document.createElement("div");
    container.classList.add("mt-1", "flex", "justify-between", "gap-x-5");
    const volumeContainer = document.createElement("div");
    volumeContainer.classList.add(
      "text-[10px]",
      "font-[400]",
      "leading-3",
      "text-secDarkText"
    );
    volumeContainer.innerText = fullName;
    container.append(
      volumeContainer,
      this.activeButton === "active"
        ? ""
        : this.pointPercentageChange(pointChange, percentageChange)
    );
    return container;
  }

  pointPercentageChange(pointChange, percentageChange) {
    const isNegative = isNegativeNumber(pointChange);
    const container = document.createElement("div");
    container.classList.add("flex", "gap-x-2");
    const pointChangeContainer = document.createElement("div");
    pointChangeContainer.classList.add(
      "text-[10px]",
      "font-[400]",
      "leading-3",
      isNegative ? "text-red" : "text-green",
      isNegative ? null : "dark:text-darkGreen"
    );
    pointChangeContainer.innerText = isNegative
      ? commaSeperatedFloats(pointChange)
      : "+" + commaSeperatedFloats(pointChange);
    const percentageChangeContainer = document.createElement("div");
    percentageChangeContainer.classList.add(
      "text-[10px]",
      "font-[400]",
      "leading-3",
      isNegative ? "text-red" : "text-green",
      isNegative ? null : "dark:text-darkGreen"
    );
    percentageChangeContainer.innerText = isNegative
      ? commaSeperatedFloats(percentageChange) + "%"
      : "+" + commaSeperatedFloats(percentageChange) + "%";
    container.append(pointChangeContainer, percentageChangeContainer);
    return container;
  }
}
