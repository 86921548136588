import { NPSTOCKS_URL } from '../Utils';

const mainContainer = document.querySelector('#smtm-indices-container');

export default class Test {
    static init({ hide }) {
        this.config = { hide };
        console.log('Init');
        document.addEventListener('DOMContentLoaded', () => Test.displayUI());
    }

    // eslint-disable-next-line consistent-return
    static async displayUI() {
        const data = await Test.loadData();
        if (data.length > 0) {
            return Test.createTableContainer(data);
        }
        return null;
    }

    static switchDarkMode() {
        console.log(mainContainer.classList);
        if (mainContainer.classList.contains('dark')) {
            mainContainer.classList.remove('dark');
        } else {
            mainContainer.classList.add('dark');
        }
    }

    static testingData() {
        console.log('TestingMe');
    }

    static async loadData() {
        console.log('Loading ');
        try {
            const data = await fetch(NPSTOCKS_URL);
            return data.json();
        } catch (e) {
            return [];
        }
    }

    static toggleSwitch() {
        const switches = document.createElement('div');
        switches.innerHTML = `
      <label for="small-toggle" class="inline-flex relative items-center mb-5 cursor-pointer">
      <input type="checkbox" value="" id="small-toggle" class="sr-only peer">
      <div class="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
      <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Small toggle</span>
    </label>
    `;
        return switches;
    }

    static createTableContainer(tableData) {
        const div = document.createElement('div');
        div.className = 'overflow-x-auto';
        const list = document.createElement('table');
        list.className = 'table table-zebra';
        const row = document.createElement('tbody');
        row.innerHTML = tableData
            .map((item) => `<tr>
                 <td>${item.userId}</td>
                 <td>${item.id}</td>
                 <td>${item.title}</td>
                 <td>${this.config.hide ? '' : item.body}</td>
                 </tr>
               `)
            .join('');
        list.appendChild(row);
        div.appendChild(list);
        mainContainer.appendChild(this.toggleSwitch());
        mainContainer.appendChild(div);
    }
}
