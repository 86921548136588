function createSpinner() {
    const div = document.createElement('div');
    div.className = 'flex items-center justify-center';
    div.innerHTML = '<div class="w-8 h-8 m-10 border-b border-gray-900 rounded-full animate-spin"></div>';
    return div;
}
function toggleTheme(date) {
    const divElement = document.createElement('div');
    divElement.className = 'flex m-0 flex-row-reverse justify-between';
    // divElement.appendChild(Indices.toggleButtonDiv());
    divElement.innerHTML += ` <div class='text-[10px] font-semibold text-black dark:text-slate-400'>
             As On ${date}
        </div>`;
    return divElement;
}

export {
    createSpinner,
    toggleTheme,
};
