import * as Apexcharts from 'apexcharts';
import {isMoblie, NPSTOCKS_LIVE_URL, NPSTOCKS_URL} from '../Utils';
import {validateToken} from '../Auth';

export default class TimeSeriesChart {
    constructor({token, id, ticker}) {
        this.config = {token, id, ticker: ticker ?? 'NEPSE'};
        this.mainContainer = document.querySelector(id);
        console.log('thisis the time series chart');
    }

    async fetchData(timeseries, ticker) {
        try {
            await isMoblie({ encyryptedText: this.config.token });
            const data = await fetch(`${NPSTOCKS_LIVE_URL}/widget/timeseries?period=${timeseries}&ticker=${ticker}`);
            const parsedData = await data.json();
            // to be changed after....
            this.apiData = parsedData.response;
        } catch (e) {
            console.log(e);
        }
    }

    getChartOptions(series) {
        // data should be [{x: , y: }]
        return {
            series: [
                {
                    name: this.config.ticker,
                    data: series.prices,
                },
            ],
            chart: {
                type: 'area',
                height: 350,
                stacked: false,
                zoom: {
                    type: 'x',
                    enabled: true,
                    autoScaleYaxis: true,
                },
                toolbar: {
                    autoSelected: 'zoom',
                },
            },
            dataLabels: {
                enabled: false,
            },

            title: {
                text: 'Fundamental Analysis of Stocks',
                align: 'left',
            },
            markers: {
                size: 0,
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 1,
                    inverseColors: false,
                    opacityFrom: 0.7,
                    opacityTo: 0,
                    stops: [0, 90, 100],
                },
            },
            subtitle: {
                text: 'Price Movements',
                align: 'left',
            },
            labels: series.dates,
            xaxis: {
                type: 'datetime',
            },
            yaxis: {
                opposite: false,
            },
            legend: {
                horizontalAlign: 'left',
            },
            tooltip: {
                shared: false,
                y: {
                    formatter(val) {
                        return `${val}`;
                    },
                },
            },
        };
    }

    async init() {
        const isOk = await validateToken(this.config.token, this.mainContainer);
        if (!isOk) {
            return;
        }
        await this.fetchData('1D', this.config.ticker);
        this.renderOuterDiv(this.apiData);
    }

    renderOuterDiv() {
        this.mainContainer.innerHTML = '';
        this.mainContainer.classList.add('m-2', 'border-2', 'p-4', 'bg-white', 'dark:bg-dark', 'text-black', 'dark:text-slate-300');

        const chartDiv = document.createElement('div');
        this.mainContainer.appendChild(this.renderSwitchCase());
        this.mainContainer.appendChild(chartDiv);
        this.chartDiv = chartDiv;
        this.renderChart(chartDiv, this.apiData);
    }

    renderSwitchCase() {
        const buttonsList = ['1D', '1M', '6M', '1Y', '5Y', 'All'];
        const div = document.createElement('div');
        div.className = 'flex my-2 gap-x-4';
        buttonsList.forEach((item) => {
            const button = document.createElement('div');
            button.classList.add('mx-2', 'text-sm', 'text-gray-900', 'cursor-pointer', 'border-b', 'dark:text-slate-500');
            if (item === '1D') {
                button.classList.add('text-gray-900', 'font-semibold', 'border-gray-900');
            }
            button.addEventListener('click', async () => {
                // eslint-disable-next-line no-restricted-syntax
                for (const innerButton of Array.from(div.children)) {
                    if (innerButton.innerHTML === item) {
                        innerButton.classList.add('text-gray-900', 'font-semibold', 'border-gray-900', 'dark:border-gray-400');
                        // eslint-disable-next-line no-await-in-loop
                        await this.fetchData(innerButton.innerHTML, this.config.ticker);
                        // this.apiData = {prices: this.apiData.prices.slice(0, 5), dates: this.apiData.dates.slice(0,
                        // 5)};
                        await this.chart.updateOptions(this.getChartOptions(this.apiData));
                    } else {
                        innerButton.classList.remove('text-gray-900', 'font-semibold', 'border-gray-900');
                    }
                }
            });
            button.innerHTML = item;
            div.appendChild(button);
        });
        return div;
    }

    renderChart(chartDiv, apiData) {
        const chart = new Apexcharts(chartDiv, this.getChartOptions(apiData));
        chart.render();
        this.chart = chart;
    }
}
