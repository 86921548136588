// can be used globally through window func
window.switchDarkTheme = function themeSwitcher() {
  // On page load or when changing themes, best to add inline in `head` to avoid FOUCs
  const themeSwitch = document.querySelectorAll("#smtm-theme-toggle");
  if (localStorage.getItem("theme") === "dark") {
    document.documentElement.classList.remove("dark");
    localStorage.setItem("theme", "light");
  } else {
    localStorage.setItem("theme", "dark");
    document.documentElement.classList.add("dark");
  }
  themeSwitch?.forEach((theme) => {
    theme.classList.toggle("active");
  });
};

// eslint-disable-next-line import/no-self-import
export { default as TestWidget } from "./TestWidget/index";
export { default as Indices } from "./Indices/index";
export { default as GainerLoserTab } from "./GainerLoserTab";
export { default as StockPriceTab } from "./StockPriceTab";
export { default as Announcement } from "./Announcement";
export { default as TickerTape } from "./TickerTape";
export { default as TimeSeriesChart } from "./TimeSeriesChart";
export { default as ScrollableTicker } from "./Ticker/scrollableTicker";
export { default as TradingviewLightWeightChart } from "./TradingviewLightWeightChart";
export { default as ActiveBrokersTab } from "./ActiveBrokers";
export { default as DividendHistory } from "./DividendHistory";

export { default as WhatIf } from "./Whatif/index";
export { default as Ticker } from "./Ticker/index";
export { default as WhatIfTradingView } from "./Whatif/TradingViewWhatIf";
export { default as IPO } from "./IPO";
export { default as MutualFund } from "./MutualFund";
export { default as SymbolInfo } from "./SymbolInfo/SymbolInfo";
export { default as SymbolInfoSmall } from "./SymbolInfo/SymbolInfoSmall";
export { default as SymbolInfoSmallMobile } from "./SymbolInfo/SymbolInfoSmallMobile";
export { default as TodayEvent } from "./TodayEvent/TodaysEventWeb";
export { default as Calendar } from "./calendar/index";
export { default as ChartWidget } from "./ChartWidget/ChartWidget";
export { default as ChartWidgetSmall } from "./ChartWidget/ChartWidgetSmall";
export { default as KeyMetricsEquity } from "./KeyMetrics/equityIndex";
export { default as KeyMetricsMutualFund } from "./KeyMetrics/mutualFundKeyMetrics";
export { default as TechnicalIndicator } from "./TechnicalIndicator/index";
export { default as SearchQuery } from "./querySelector/index";
