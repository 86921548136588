// import decryptAndBase64 from "./encryptionDecryption";

// const NPSTOCKS_URL = 'https://jsonplaceholder.typicode.com/posts';
// const NPSTOCKS_URL = "https://widgets.smtmcapital.com.np";
const NPSTOCKS_URL = "https://widgets.prabhucapital.com";
// const NPSTOCKS_URL = 'http://localhost:3005';
// export const NPSTOCKS_LIVE_URL = "https://widgets.smtmcapital.com.np";
export const NPSTOCKS_LIVE_URL = "https://widgets.prabhucapital.com";
let tokenFetchURL;
let isTokenFetchURLSET = false;

export const getUrlForTokenFetch = async () => {
  try {
    const response = await fetch(
      NPSTOCKS_LIVE_URL + "/widget/getTokenFetchUrl"
    );
    const parsed = await response.json();
    if (parsed.code === 200) {
      tokenFetchURL = parsed.response;
    } else {
      tokenFetchURL = "https://tokenfetchapinotworking.com";
      isTokenFetchURLSET = false;
    }
    isTokenFetchURLSET = true;
  } catch (error) {
    isTokenFetchURLSET = false;
    console.log(error);
    tokenFetchURL = "https://tokenfetchapinotworking.com";
  }
};
export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const allowedExecutor = [
  {
    executor: "Prabhu Mobile App",
  },
];

// export function isMoblie({ encyryptedText }) {
//   // console.log(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent), "test");
//   let isDecryptionValid = false;
//   // if (!window.navigator.userAgentData.mobile) {
//   if (!/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
//     throw new Error("Error, Device is not mobile");
//   }
//   try {
//     decryptAndBase64(encyryptedText);
//   } catch (e) {
//     throw new Error("Failed to decrypt payload");
//   }
//   allowedExecutor.forEach((data) => {
//     if (decryptAndBase64(encyryptedText).executor === data.executor) {
//       isDecryptionValid = true;
//     }
//   });
//   if (!isDecryptionValid) {
//     throw new Error("Failed to decrypt payload");
//   }
//   return isDecryptionValid;
// }

export async function isMoblie({ encyryptedText }) {
  let isDecryptionValid = false;
  if (!isTokenFetchURLSET) {
    await getUrlForTokenFetch();
  }
  try {
    isDecryptionValid = await fetchAuthToken(encyryptedText);
    console.log("encrypted", encyryptedText);
  } catch (e) {
    throw new Error("Failed to decrypt payload");
  }

  if (!isDecryptionValid) {
    throw new Error("Failed to decrypt payload");
  }
  return isDecryptionValid;
}

async function fetchAuthToken(token) {
  try {
    let data = await fetch(tokenFetchURL + `/api/v1/auth/checkWidgetToken?token=${token}`);
    data = await data.json();
    if (data.status === "fail") {
      return false;
    } else {
      return true;
    }
  } catch (e) {
    throw new Error("Failed to get authorization payload");
  }
}

// export function isMoblie({ encyryptedText }) {
//   let isDecryptionValid = false;
//   if (!/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
//     throw new Error("Error, Device is not mobile");
//   }

//   if (
//     encyryptedText ===
//     "54vhzi2Fu8aVDEg72ktevPATuCmcbrcffywnaH6Hq1c="
//   ) {
//     isDecryptionValid = true;
//   } else {
//     throw new Error("Failed to decrypt payload");
//   }
//   return isDecryptionValid;
// }

function getZeroBeforeNum(num) {
  if (num > 9) {
    return num;
  }
  return "0" + num;
}

export function getHours(date) {
  return `${
    date.getHours() > 12
      ? getZeroBeforeNum(Math.abs(12 - date.getHours()))
      : getZeroBeforeNum(date.getHours())
  }:${getZeroBeforeNum(date.getMinutes())} ${
    date.getHours() > 11 ? "PM" : "AM"
  }`;
}

export function amountAbbreviation(amount) {
  let transactionAmount = 0;
  if (amount > 1000000000) {
    transactionAmount = amount / 1000000000;
    transactionAmount = transactionAmount.toFixed(2) + " Ar";
  } else if (amount > 10000000) {
    transactionAmount = amount / 10000000;
    transactionAmount = transactionAmount.toFixed(2) + " Cr";
  } else if (amount > 100000) {
    transactionAmount = amount / 100000;
    transactionAmount = transactionAmount.toFixed(2) + " Lac";
  } else if (amount >= 1000 && amount < 100000) {
    transactionAmount = amount / 1000;
    transactionAmount = transactionAmount.toFixed(2) + " K";
  } else {
    return amount.toFixed(2);
  }
  return transactionAmount;
}

export function commaSeperatedFloats(num) {
  const [integer, fraction] = num.toFixed(2).toString().split(".");
  return `${parseInt(integer, 10).toLocaleString("en-US")}.${fraction}`;
}

function formatTwoNumber(value) {
  if (typeof value === "number" && value % 1 !== 0)
    return parseFloat(value.toFixed(2));
  return value;
}

function formatNumber(number) {
  if (typeof number === "number") {
    return parseFloat(number.toFixed(2));
  }
  return "-";
}

function isNegativeNumber(number) {
  if (typeof number === "string") {
    number = +number;
  }
  return !Object.is(Math.abs(number), number);
}

// get only selected key with value from object
function getSelectedKey(obj, key) {
  const result = {};
  key.forEach((item) => {
    if (obj[item]) {
      result[item] = obj[item];
    }
  });
  return result;
}

function toggleThemeButton() {
  console.log("Toggling theme");
}

function toggleId(id) {
  if (id === undefined) {
    return 1;
  }
  if (id === 1) {
    return -1;
  }
  return 1;
}

function formatString(val) {
  return val.replace(/([a-z])([A-Z])/g, "$1 $2");
}

// for tab bar
const initTabClass = [
  "px-2",
  "py-1",
  "md:px-4",
  "cursor-pointer",
  "m-1",
  "rounded",
  "text-black",
  "border-blue-100",
  "dark:text-slate-300",
];
const activeTabClass = [
  "font-bold",
  "bg-slate-300",
  "dark:text-white",
  "dark:bg-slate-600",
  "border-gray-100",
  "dark:border-gray-800",
];

const formatCommasNumber = (data, isMoney) => {
  if (data) {
    const [digit, afterDigit] = formatNumber(data).toString().split(".");
    const commasDigit = digit.replace(/(\d)(?=(\d\d)+\d$)/g, "$1,");
    const formattedDigit = isMoney ? `Rs. ${commasDigit}` : commasDigit;
    if (!afterDigit) {
      return formattedDigit;
    }
    return `${formattedDigit}.${afterDigit}`;
  }
  return "";
};

export {
  // eslint-disable-next-line import/prefer-default-export
  NPSTOCKS_URL,
  toggleThemeButton,
  formatNumber,
  formatCommasNumber,
  isNegativeNumber,
  initTabClass,
  activeTabClass,
  formatTwoNumber,
  formatString,
  toggleId,
  getSelectedKey,
};
