import {
  amountAbbreviation,
  commaSeperatedFloats,
  isMoblie,
  NPSTOCKS_LIVE_URL,
} from "../Utils";

export default class TechnicalIndicator {
  constructor({ token, id, ticker }) {
    this.config = { token, id, ticker };
    this.mainContainer = document.querySelector(id);
    this.mainContainer.innerHTML = "";
    this.style = document.createElement("style");
    this.style.innerHTML = `@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
                      ${id}{font-family: 'Inter', sans-serif;}`;
    this.mainContainer.appendChild(this.style);
    this.data = {};
    this.fetchError = false;
  }

  async fetchData() {
    try {
      await isMoblie({ encyryptedText: this.config.token });
      const data = await fetch(
        `${NPSTOCKS_LIVE_URL}/widget/technicalIndicator?ticker=${this.config.ticker}`
      );
      let parsedData = await data.json();
      if (parsedData.code === 500) {
        this.fetchError = true;
        return;
      }
      this.data = parsedData.response;
      this.fetchError = false;
    } catch (e) {
      this.fetchError = true;
    }
  }

  async init() {
    await this.fetchData();
    this.renderContent();
  }

  renderContent() {
    this.mainContainer.className =
      "m-2 h-[178px] rounded-md bg-white p-3 overflow-hidden dark:bg-dark dark:text-darkText";
    this.mainContainer.id = "technicalIndicator";
    const body = document.createElement("div");
    body.className = "flex justify-between";
    body.id = "technicalIndicator-Body";
    this.mainContainer.append(body);
    if (this.fetchError) {
      body.className =
        "flex justify-center items-center dark:text-white/50 text-black/50 h-full";
      body.innerText = "Sorry, an error occured while fetching data.";
    } else {
      this.body();
    }
  }

  body() {
    const body = document.getElementById("technicalIndicator-Body");
    body.append(this.firstColumn(), this.secondColumn(), this.thirdColumn());
  }

  firstColumn() {
    const container = document.createElement("div");
    container.className = "flex flex-col gap-y-5";
    container.append(
      this.element("RSI", commaSeperatedFloats(this.data.rsi)),
      this.element("MA20", commaSeperatedFloats(this.data.ma20)),
      this.element("ADX", commaSeperatedFloats(this.data.adx))
    );
    return container;
  }

  secondColumn() {
    const container = document.createElement("div");
    container.className = "flex flex-col gap-y-5";
    container.append(
      this.element("MFI", commaSeperatedFloats(this.data.mfi)),
      this.element("MA50", commaSeperatedFloats(this.data.ma50)),
      this.element("CCI", commaSeperatedFloats(this.data.cci))
    );
    return container;
  }

  thirdColumn() {
    const container = document.createElement("div");
    container.className = "flex flex-col gap-y-5";
    container.append(
      this.element("MACD", amountAbbreviation(this.data.macd)),
      this.element("MA200", amountAbbreviation(this.data.ma200))
    );
    return container;
  }

  element(name, value) {
    const container = document.createElement("div");
    container.className = "flex flex-col gap-y-1";

    const nameContainer = document.createElement("div");
    nameContainer.className = "text-[14px] font-[600] leading-4";
    nameContainer.innerText = name;

    const valueContainer = document.createElement("div");
    valueContainer.className =
      "text-[14px] font-[500] leading-4 text-[#525B60] dark:text-secDarkText";
    valueContainer.innerText = value ?? "-";

    container.append(nameContainer, valueContainer);

    return container;
  }
}
