import { validateToken } from "../Auth";
import { isMoblie, NPSTOCKS_LIVE_URL } from "../Utils";

const dividendList = ["Fiscal Year", "Cash", "Bonus"];
export default class Index {
  constructor({ token, id, ticker }) {
    this.config = { token, id, ticker };
    this.mainContainer = document.querySelector(id);
    this.mainContainer.innerHTML = "";
    this.style = document.createElement("style");
    this.style.innerHTML = `@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
                            ${id}{font-family: 'Inter', sans-serif;}`;
    this.mainContainer.appendChild(this.style);
    this.data = {};
    this.fetchError = false;
    this.isEmpty = false;
  }

  async fetchData() {
    try {
      this.fetchError = false;
      await isMoblie({ encyryptedText: this.config.token });
      const data = await fetch(
        `${NPSTOCKS_LIVE_URL}/widget/dividendHistory?ticker=${this.config.ticker}`
      );
      const parsedData = await data.json();
      this.isEmpty = parsedData.response.length === 0;
      this.data = parsedData.response;
      // this.data = this.data.fiscalYear(element => !element.fiscalYear);
      this.data = this.data.filter((element) => element.fiscalYear);
    } catch (e) {
      console.log(e);
      this.fetchError = true;
    }
  }

  async init() {
    // const isOk = await validateToken(this.config.token, this.mainContainer);
    // if (!isOk) {
    //   return;
    // }
    await this.fetchData();
    this.showContent();
  }

  showContent() {
    this.mainContainer.className =
      "m-2 h-[253px] overflow-hidden rounded-md bg-white px-1 py-2 dark:bg-dark dark:text-darkText";
    const body = document.createElement("div");
    body.className = "mt-2 h-[98%] overflow-auto xsm:scrollbar";
    const dataBody = document.createElement("div");
    dataBody.id = "DividendHistory-Body";
    body.append(this.titleContainer(), dataBody);
    this.mainContainer.append(body);
    if (this.isEmpty) {
      this.errorContainer({ calledFrom: "empty-data" });
      return;
    }
    if (this.fetchError) {
      this.errorContainer({ calledFrom: "" });
    } else {
      for (let data of this.data) {
        dataBody.append(
          this.elementContainer(data.fiscalYear, data.cash, data.bonus)
        );
      }
    }
  }

  errorContainer({ calledFrom = "" }) {
    const bodyContainer = document.getElementById("DividendHistory-Body");
    bodyContainer.classList.add(
      "h-[90%]",
      "flex",
      "justify-center",
      "items-center"
    );
    bodyContainer.innerHTML =
      calledFrom === ""
        ? `<div class="dark:text-white/50 text-black/50">
    Failed to fetch Data.
  </div>`
        : `<div class="dark:text-white/50 text-black/50">
  Sorry, No Data Found.
</div>`;
    // this.mainContainer.append(bodyContainer);
  }

  titleContainer() {
    const container = document.createElement("div");
    container.className =
      "sticky top-0 grid grid-cols-[1.2fr_1fr_.3fr] bg-white px-3 pb-[4px] dark:bg-dark";
    for (let element of ["Fiscal Year", "Cash", "Bonus"]) {
      const elementContainer = document.createElement("div");
      elementContainer.className = "text-[14px] font-[600] leading-4";
      elementContainer.innerText = element;
      container.append(elementContainer);
    }
    return container;
  }

  elementContainer(fiscalYear, cash, bonus) {
    const container = document.createElement("div");
    container.className =
      "grid grid-cols-[1.2fr_1fr_.3fr] border-b border-lightBorderColor dark:border-darkBorderColor px-3 py-2 text-[#525B60] hover:bg-lightHoverColor dark:text-darkText dark:hover:bg-slate-800";
    for (let value of [fiscalYear, cash, bonus]) {
      const element = document.createElement("div");
      element.className = "text-[14px] font-[400] leading-4";

      element.innerText = value;

      container.append(element);
    }
    return container;
  }
}
