export default class Calendar {
  constructor({ token, id, day, month, year, onclick }) {
    this.config = { token, id };
    this.mainContainer = document.querySelector(id);
    this.mainContainer.innerHTML = "";
    this.style = document.createElement("style");
    this.style.innerHTML = `@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                  ${id}{font-family: 'Poppins', sans-serif;}`;
    this.mainContainer.appendChild(this.style);
    this.date = new Date();
    this.day = day ?? this.date.getDate();
    this.month = month ?? this.date.getMonth();
    this.year = year ?? this.date.getFullYear();
    this.isLeapYear = this.year % 4 === 0;
    this.selectedMonth = this.month;
    this.selectedYear = this.year;
    this.selectedDay = this.day;
    this.months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    this.onclick = onclick;
    this.calendarContainer = document.createElement("div");
    this.mainContainer.append(this.calendarContainer);
    window.addEventListener("click", (e) => {
      if (e.target.id === "calendar-widget-container") {
        e.target.classList.add("hidden");
      }
    });
  }

  async init() {
    this.calendarContainer.innerHTML = "";
    this.renderContent();
  }

  renderContent() {
    this.calendarContainer.classList.add(
      "h-[288px]",
      "w-[346px]",
      "select-none",
      "rounded-[20px]",
      "bg-white",
      "dark:bg-dark",
      "dark:text-darkText",
      "pl-[11px]",
      "pr-[13px]",
      "pt-[8px]",
      "shadow-md",
      "overflow-hidden",
      "pb-[20px]"
    );
    this.calendarContainer.append(
      this.headerNav(this.months[this.selectedMonth], this.selectedYear),
      this.dateGrid()
    );
  }

  headerNav(month, year) {
    const containerNav = document.createElement("nav");
    containerNav.classList.add("flex", "justify-between");

    const dateContainer = document.createElement("div");
    dateContainer.classList.add(
      "ml-[7px]",
      "flex",
      "items-baseline",
      "space-x-1"
    );
    const monthContainer = document.createElement("h1");
    monthContainer.classList.add(
      "cursor-pointer",
      "text-[15px]",
      "font-[500]",
      "leading-[22px]",
      "tracking-[0.03em]"
    );
    const yearContainer = document.createElement("h1");
    yearContainer.classList.add(
      "cursor-pointer",
      "text-[25px]",
      "font-[500]",
      "leading-[38px]",
      "tracking-[0.03em]"
    );
    monthContainer.innerText = month;
    yearContainer.innerText = year;
    dateContainer.append(monthContainer, yearContainer);

    const navigator = document.createElement("div");
    navigator.classList.add("mt-1", "mr-2", "flex", "space-x-1");

    const leftArrow = document.createElement("div");
    leftArrow.classList.add(
      "flex",
      "h-8",
      "w-6",
      "cursor-pointer",
      "items-center",
      "justify-center"
    );
    leftArrow.innerHTML = `<svg class="dark:stroke-white stroke-black" width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.23242 0.963256L1.48242 4.64217L5.23242 8.32108" stroke-linecap="round" stroke-linejoin="round" />
    </svg>`;
    leftArrow.onclick = () => {
      if (this.selectedMonth === 0) {
        this.selectedMonth = 11;
        this.selectedYear -= 1;
      } else {
        this.selectedMonth -= 1;
      }
      this.isLeapYear = this.selectedYear % 4 === 0;
      this.calendarContainer.innerHTML = "";
      this.renderContent();
    };

    const rightArrow = document.createElement("div");
    rightArrow.classList.add(
      "flex",
      "h-8",
      "w-6",
      "cursor-pointer",
      "items-center",
      "justify-center"
    );
    rightArrow.innerHTML = `<svg class="dark:stroke-white stroke-black" width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.48242 8.03674L5.23242 4.35783L1.48242 0.678925"  stroke-linecap="round" stroke-linejoin="round" />
    </svg>`;

    rightArrow.onclick = () => {
      if (this.selectedMonth === 11) {
        this.selectedMonth = 0;
        this.selectedYear += 1;
      } else {
        this.selectedMonth += 1;
      }
      this.isLeapYear = this.selectedYear % 4 === 0;
      this.calendarContainer.innerHTML = "";
      this.renderContent();
    };

    navigator.append(leftArrow, rightArrow);

    containerNav.append(dateContainer, navigator);
    return containerNav;
  }

  dateGrid() {
    const containerDiv = document.createElement("div");
    containerDiv.classList.add(
      "mt-[19px]",
      "grid",
      "grid-cols-6",
      "grid-rows-6",
      "gap-x-8",
      "gap-y-2"
    );

    if (
      this.selectedMonth === 0 ||
      this.selectedMonth === 2 ||
      this.selectedMonth === 4 ||
      this.selectedMonth === 6 ||
      this.selectedMonth === 7 ||
      this.selectedMonth === 9 ||
      this.selectedMonth === 11
    ) {
      let dayCount = 0;
      let prevMonth = undefined;
      let nextMonth = undefined;
      if (this.selectedMonth === 0) {
        prevMonth = 11;
      } else {
        prevMonth = this.selectedMonth - 1;
      }

      if (this.selectedMonth === 11) {
        nextMonth = 0;
      } else {
        nextMonth = this.selectedMonth + 1;
      }
      if (
        prevMonth === 3 ||
        prevMonth === 5 ||
        prevMonth === 8 ||
        prevMonth === 10
      ) {
        for (let i = 28; i < 31; i++) {
          dayCount += 1;
          containerDiv.append(this.dayContainer(i, "unselect"));
        }
      }
      for (let i = 1; i < 32; i++) {
        dayCount += 1;
        if (
          this.selectedYear === this.year &&
          this.selectedMonth === this.month &&
          this.selectedDay === i
        ) {
          containerDiv.append(this.dayContainer(i, "active"));
        } else {
          containerDiv.append(this.dayContainer(i, "select"));
        }
      }
      for (let i = 1; i < 6; i++) {
        dayCount += 1;
        containerDiv.append(this.dayContainer(i, "unselect"));
        if (dayCount === 36) {
          break;
        }
      }
    } else if (
      this.selectedMonth === 3 ||
      this.selectedMonth === 5 ||
      this.selectedMonth === 8 ||
      this.selectedMonth === 10
    ) {
      let dayCount = 0;
      let prevMonth = undefined;
      let nextMonth = undefined;
      if (this.selectedMonth === 0) {
        prevMonth = 11;
      } else {
        prevMonth = this.selectedMonth - 1;
      }

      if (this.selectedMonth === 11) {
        nextMonth = 0;
      } else {
        nextMonth = this.selectedMonth + 1;
      }
      if (
        prevMonth === 0 ||
        prevMonth === 2 ||
        prevMonth === 4 ||
        prevMonth === 6 ||
        prevMonth === 7 ||
        prevMonth === 9 ||
        prevMonth === 11
      ) {
        for (let i = 28; i < 32; i++) {
          dayCount += 1;
          containerDiv.append(this.dayContainer(i, "unselect"));
        }
      }
      for (let i = 1; i < 31; i++) {
        dayCount += 1;
        if (
          this.selectedYear === this.year &&
          this.selectedMonth === this.month &&
          this.selectedDay === i
        ) {
          containerDiv.append(this.dayContainer(i, "active"));
        } else {
          containerDiv.append(this.dayContainer(i, "select"));
        }
      }
      for (let i = 1; i < 6; i++) {
        dayCount += 1;
        containerDiv.append(this.dayContainer(i, "unselect"));
        if (dayCount === 36) {
          break;
        }
      }
    } else if (this.selectedMonth === 1) {
      let dayCount = 0;
      let prevMonth = undefined;
      let nextMonth = undefined;
      if (this.selectedMonth === 0) {
        prevMonth = 11;
      } else {
        prevMonth = this.selectedMonth - 1;
      }

      if (this.selectedMonth === 11) {
        nextMonth = 0;
      } else {
        nextMonth = this.selectedMonth + 1;
      }
      for (let i = 28; i < 32; i++) {
        dayCount += 1;
        containerDiv.append(this.dayContainer(i, "unselect"));
      }
      if (this.isLeapYear) {
        for (let i = 1; i < 30; i++) {
          dayCount += 1;
          if (
            this.selectedYear === this.year &&
            this.selectedMonth === this.month &&
            this.selectedDay === i
          ) {
            containerDiv.append(this.dayContainer(i, "active"));
          } else {
            containerDiv.append(this.dayContainer(i, "select"));
          }
        }
      } else {
        for (let i = 1; i < 29; i++) {
          dayCount += 1;
          if (
            this.selectedYear === this.year &&
            this.selectedMonth === this.month &&
            this.selectedDay === i
          ) {
            containerDiv.append(this.dayContainer(i, "active"));
          } else {
            containerDiv.append(this.dayContainer(i, "select"));
          }
        }
      }
      for (let i = 1; i < 6; i++) {
        dayCount += 1;
        containerDiv.append(this.dayContainer(i, "unselect"));
        if (dayCount === 36) {
          break;
        }
      }
    }

    return containerDiv;
  }

  dayContainer(day, type) {
    const containerDiv = document.createElement("div");
    containerDiv.classList.add(
      "flex",
      "h-[27px]",
      "w-[27px]",
      "items-center",
      "justify-center"
    );

    if (type === "select") {
      containerDiv.classList.add(
        "hover:bg-black",
        "dark:hover:bg-slate-100/40",
        "hover:text-white",
        "cursor-pointer"
      );
      containerDiv.onclick = () => {
        this.selectedDay = day;
        this.year = this.selectedYear;
        this.month = this.selectedMonth;
        this.day = this.selectedDay;
        this.date = new Date(
          this.selectedYear,
          this.selectedMonth,
          this.selectedDay
        );
        this.onclick();
      };
    } else if (type === "unselect") {
      containerDiv.classList.add(
        "cursor-not-allowed",
        "text-black/[0.2]",
        "dark:text-white/[0.2]"
      );
    } else if (type === "active") {
      containerDiv.classList.add(
        "dark:bg-slate-100/40",
        "bg-black",
        "text-white",
        "hover:bg-black",
        "hover:text-white",
        "cursor-pointer"
      );
      containerDiv.onclick = () => {
        this.selectedDay = day;
        this.year = this.selectedYear;
        this.month = this.selectedMonth;
        this.day = this.selectedDay;
        this.date = new Date(
          this.selectedYear,
          this.selectedMonth,
          this.selectedDay
        );
        this.onclick();
      };
    }

    const elementDiv = document.createElement("div");
    elementDiv.classList.add(
      "text-[15px]",
      "font-[500]",
      "leading-[15px]",
      "tracking-[0.03em]"
    );
    elementDiv.innerText = day;

    containerDiv.appendChild(elementDiv);

    return containerDiv;
  }
}
