export default class SeachQuery {
  constructor() {
    this.queryParam = this.parseQuery();
  }

  parseQuery() {
    const symbol = new URLSearchParams(window.location.search);
    return Object.fromEntries(symbol.entries());
  }
}
