// import { validateToken } from "../Auth";
import {
  amountAbbreviation,
  commaSeperatedFloats,
  getHours,
  isMoblie,
  isNegativeNumber,
  MONTHS,
  NPSTOCKS_LIVE_URL,
} from "../Utils";

export default class Indices {
  constructor({ token, id }) {
    this.config = { token, id };
    this.mainContainer = document.querySelector(id);
    this.mainContainer.innerHTML = "";
    this.style = document.createElement("style");
    this.style.innerHTML = `@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
                    ${id}{font-family: 'Inter', sans-serif;}`;
    this.mainContainer.appendChild(this.style);
    this.data = [];
    this.date = new Date();
    this.fetchError = false;
  }

  async init() {
    // const isOk = await validateToken(this.config.token, this.mainContainer);
    // if (!isOk) {
    //   return;
    // }
    await this.fetchData();
    this.showContent();
  }

  async fetchData() {
    try {
      await isMoblie({ encyryptedText: this.config.token });
      const data = await fetch(`${NPSTOCKS_LIVE_URL}/widget/indices`);
      const response = await data.json();
      this.data = response.response;
      this.date = new Date(this.data[0].timestamp);
    } catch (e) {
      console.log(e);
      this.fetchError = true;
    }
  }

  showContent() {
    this.mainContainer.classList.add(
      "rounded-lg",
      "bg-white",
      "py-2",
      "m-2",
      "h-[433px]",
      "overflow-hidden",
      // "dark:bg-[#1E222D]",
      "dark:bg-dark"
      // "dark:text-white"
    );
    if (!this.fetchError) {
      this.mainContainer.append(this.header(), this.body());
    } else {
      this.mainContainer.append(this.header(), this.errorBody());
    }
  }

  header() {
    const dateContainer = document.createElement("nav");
    // dateContainer.classList.add("mb-4", "flex", "justify-end", "px-6", "dark:text-[#767884]");
    dateContainer.classList.add(
      "mb-4",
      "flex",
      "justify-end",
      "px-6",
      "dark:text-secDarkText"
    );
    const date = document.createElement("div");
    date.classList.add("text-[10px]", "font-[400]", "leading-3");
    // date.innerText = `as on June 30, 2022 1:01 PM`;

    date.innerText = `as on ${
      MONTHS[this.date.getMonth()]
    } ${this.date.getDate()}, ${this.date.getFullYear()} ${getHours(
      this.date
    )}`;
    dateContainer.append(date);
    return dateContainer;
  }

  errorBody() {
    const dataContainer = document.createElement("div");
    dataContainer.id = "Indices-Widget-Body-Container";
    dataContainer.classList.add(
      "flex",
      "justify-center",
      "items-center",
      "h-full",
      "text-black/30",
      "dark:text-white/30"
    );
    dataContainer.innerText = "Unable to fetch data.";
    return dataContainer;
  }

  body() {
    const dataContainer = document.createElement("div");
    dataContainer.id = "Indices-Widget-Body-Container";
    dataContainer.classList.add(
      "overflow-auto",
      "xsm:scrollbar",
      "h-[93%]",
      "dark:text-darkText"
    );
    this.data.map((data) => {
      dataContainer.append(
        this.elementData(
          data.sector,
          data.points,
          data.turnover,
          data.changedPoint,
          data.changedPercentage
        )
      );
    });
    return dataContainer;
  }

  elementData(symbol, ltp, volume, pointChange, percentageChange) {
    const container = document.createElement("div");
    container.classList.add(
      // "cursor-pointer",
      "px-6",
      "hover:bg-lightHoverColor",
      "dark:hover:bg-darkHoverColor"
    );
    const dataContainer = document.createElement("div");
    dataContainer.classList.add(
      "border-b",
      "border-lightBorderColor",
      "dark:border-darkBorderColor",
      "py-2"
    );
    dataContainer.append(
      this.symbolLtp(symbol, ltp),
      this.volumeChange(volume, pointChange, percentageChange)
    );
    container.append(dataContainer);
    return container;
  }

  symbolLtp(symbol, ltp) {
    const container = document.createElement("div");
    container.classList.add("flex", "justify-between");
    const symbolContainer = document.createElement("div");
    symbolContainer.classList.add(
      "text-[14px]",
      "font-[400]",
      "leading-4",
      "dark:text-priDarkText"
    );
    symbolContainer.innerText = symbol;
    const ltpContainer = document.createElement("div");
    ltpContainer.classList.add("text-[14px]", "font-[400]", "leading-4");
    ltpContainer.innerText = commaSeperatedFloats(ltp);
    container.append(symbolContainer, ltpContainer);
    return container;
  }

  volumeChange(volume, pointChange, percentageChange) {
    const container = document.createElement("div");
    container.classList.add("mt-1", "flex", "justify-between");
    const volumeContainer = document.createElement("div");
    volumeContainer.classList.add(
      "text-[10px]",
      "font-[400]",
      "leading-3",
      "text-secDarkText"
    );
    volumeContainer.innerText = amountAbbreviation(volume);
    container.append(
      volumeContainer,
      this.pointPercentageChange(pointChange, percentageChange)
    );
    return container;
  }

  pointPercentageChange(pointChange, percentageChange) {
    const isNegative = isNegativeNumber(pointChange);
    const container = document.createElement("div");
    container.classList.add("flex", "gap-x-2");
    const pointChangeContainer = document.createElement("div");
    pointChangeContainer.classList.add(
      "text-[10px]",
      "font-[400]",
      "leading-3",
      isNegative ? "text-red" : "text-green",
      isNegative ? "text-red" : "dark:text-darkGreen"
    );
    pointChangeContainer.innerText = isNegative
      ? commaSeperatedFloats(pointChange)
      : "+ " + commaSeperatedFloats(pointChange);
    const percentageChangeContainer = document.createElement("div");
    percentageChangeContainer.classList.add(
      "text-[10px]",
      "font-[400]",
      "leading-3",
      isNegative ? "text-red" : "text-green",
      isNegative ? "text-red" : "dark:text-darkGreen"
    );
    percentageChangeContainer.innerText = isNegative
      ? commaSeperatedFloats(percentageChange) + "%"
      : "+ " + commaSeperatedFloats(percentageChange) + "%";
    container.append(pointChangeContainer, percentageChangeContainer);
    return container;
  }
}
